@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Quicksand:wght@400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Manrope:wght@400;500;600;700&display=swap);
:root {
  /***NEW PALLETE SCHEME***/
  --black: #000000;
  --white: #ffffff;
  /*PRIMARY COLORS*/
  /*Grey*/
  --grey0: #f7f7fa;
  --grey5: #ededf0;
  --grey10: #e1e1e3;
  --grey20: #d2d2d6;
  --grey30: #b4b4bb;
  --grey40: #9696a0;
  --grey50: #787885;
  --grey60: #5a5b6a;
  --grey70: #4a4b57;
  --grey80: #3a3a44;
  --grey90: #292a31;
  --grey100: #19191d;
  /*Blue*/

  --blue0: #f5f8ff;
  --blue5: #ebf2ff;
  --blue10: #d8e6ff;
  --blue20: #c4daff;
  --blue30: #9dc2ff;
  --blue40: #76a9ff;
  --blue50: #4f91ff;
  --blue60: #2979ff;
  --blue70: #2264d1;
  --blue80: #1b4ea3;
  --blue90: #133774;
  --blue100: #0c2146;
  /*Green*/
  --green0: #f5faf5;
  --green5: #ecf7ed;
  --green10: #dceddd;
  --green20: #cbe5cc;
  --green30: #a9d3ab;
  --green40: #87c289;
  --green50: #65b168;
  --green60: #43a047;
  --green70: #37833b;
  --green80: #2b662e;
  --green90: #1f4921;
  --green100: #132c14;
  /*Yellow*/
  --yellow0: #fff8eb;
  --yellow5: #ffefd1;
  --yellow10: #ffe5b3;
  --yellow20: #ffd98f;
  --yellow30: #f5ce84;
  --yellow40: #ebbf67;
  --yellow50: #e5ae40;
  --yellow60: #d6981b;
  --yellow70: #b88217;
  --yellow80: #8f6512;
  --yellow90: #66480d;
  --yellow100: #463209;
  /*Red*/
  --red0: #fef2f1;
  --red5: #fee8e7;
  --red10: #fddcda;
  --red20: #fccbc8;
  --red30: #faa9a3;
  --red40: #f8877f;
  --red50: #f6655a;
  --red60: #f44336;
  --red70: #c8372d;
  --red80: #9c2b23;
  --red90: #6f1f19;
  --red100: #43130f;
  /*SECONDARY COLORS*/
  /*Indigo*/
  --indigo0: #f7f8fc;
  --indigo5: #eff0fa;
  --indigo10: #e1e4f3;
  --indigo20: #d2d6ed;
  --indigo30: #b4bbe2;
  --indigo40: #97a0d6;
  --indigo50: #7985cb;
  --indigo60: #5c6bc0;
  --indigo70: #4c589e;
  --indigo80: #3b457b;
  --indigo90: #2a3158;
  --indigo100: #1a1e35;
  /*Teal*/
  --teal0: #f0fafa;
  --teal5: #e5f5f5;
  --teal10: #d1ebec;
  --teal20: #bbe2e2;
  --teal30: #8dcfcf;
  --teal40: #60bcbc;
  --teal50: #33a9a9;
  --teal60: #069697;
  --teal70: #057b7c;
  --teal80: #046061;
  --teal90: #034545;
  --teal100: #02292a;
  /*Orange*/
  --orange0: #fff6ed;
  --orange5: #ffefde;
  --orange10: #fee7cd;
  --orange20: #fddcb9;
  --orange30: #fdc68b;
  --orange40: #fcaf5c;
  --orange50: #fb982e;
  --orange60: #fb8200;
  --orange70: #ce6b00;
  --orange80: #a05300;
  --orange90: #733c00;
  --orange100: #452400;
  /*Pink*/
  --pink0: #fff7fa;
  --pink5: #fdedf2;
  --pink10: #fbdce6;
  --pink20: #f9cada;
  --pink30: #f6a8c2;
  --pink40: #f285aa;
  --pink50: #ef6292;
  --pink60: #ec407a;
  --pink70: #c23564;
  --pink80: #97294e;
  --pink90: #6c1e38;
  --pink100: #411222;

  /* SHADOWS */
  --greyShadow2dp: 0px 2px 4px rgba(90, 91, 106, 0.24),
    0px 1px 2px rgba(58, 58, 68, 0.24);
  --greyShadow4dp: 0px 4px 8px rgba(90, 91, 106, 0.2),
    0px 2px 4px rgba(58, 58, 68, 0.2);
  --greyShadow8dp: 0px 8px 16px rgba(90, 91, 106, 0.16),
    0px 4px 8px rgba(58, 58, 68, 0.16);
  --greyShadow16dp: 0px 16px 32px rgba(90, 91, 106, 0.12),
    0px 8px 16px rgba(58, 58, 68, 0.12);
  --greyShadow24dp: 0px 24px 48px rgba(90, 91, 106, 0.08),
    0px 12px 24px rgba(58, 58, 68, 0.08);

  --blueShadow2dp: 0px 2px 4px rgba(41, 121, 255, 0.24),
    0px 1px 2px rgba(27, 78, 163, 0.24);
  --blueShadow4dp: 0px 4px 8px rgba(41, 121, 255, 0.2),
    0px 2px 4px rgba(27, 78, 163, 0.2);
  --blueShadow8dp: 0px 8px 16px rgba(41, 121, 255, 0.16),
    0px 4px 8px rgba(27, 78, 163, 0.16);
  --blueShadow16dp: 0px 16px 32px rgba(41, 121, 255, 0.12),
    0px 8px 16px rgba(27, 78, 163, 0.12);
  --blueShadow24dp: 0px 24px 48px rgba(41, 121, 255, 0.08),
    0px 12px 24px rgba(27, 78, 163, 0.08);

  --redShadow2dp: 0px 2px 4px rgba(252, 203, 200, 0.24),
    0px 1px 2px rgba(156, 43, 35, 0.24);
  --redShadow4dp: 0px 4px 8px rgba(244, 67, 54, 0.2),
    0px 2px 4px rgba(156, 43, 35, 0.2);
  --redShadow8dp: 0px 8px 16px rgba(244, 67, 54, 0.16),
    0px 4px 8px rgba(156, 43, 35, 0.16);
  --redShadow16dp: 0px 16px 32px rgba(244, 67, 54, 0.12),
    0px 8px 16px rgba(156, 43, 35, 0.12);
  --redShadow24dp: 0px 24px 48px rgba(244, 67, 54, 0.08),
    0px 12px 24px rgba(156, 43, 35, 0.08);

  --greenShadow2dp: 0px 2px 4px rgba(67, 160, 71, 0.24),
    0px 1px 2px rgba(43, 102, 46, 0.24);
  --greenShadow4dp: 0px 4px 8px rgba(67, 160, 71, 0.2),
    0px 2px 4px rgba(43, 102, 46, 0.2);
  --greenShadow8dp: 0px 8px 16px rgba(67, 160, 71, 0.16),
    0px 4px 8px rgba(43, 102, 46, 0.16);
  --greenShadow16dp: 0px 16px 32px rgba(67, 160, 71, 0.12),
    0px 8px 16px rgba(43, 102, 46, 0.12);
  --greenShadow24dp: 0px 24px 48px rgba(67, 160, 71, 0.08),
    0px 12px 24px rgba(43, 102, 46, 0.08);

  --yellowShadow2dp: 0px 2px 4px rgba(214, 152, 27, 0.24),
    0px 1px 2px rgba(143, 101, 18, 0.24);
  --yellowShadow4dp: 0px 4px 8px rgba(214, 152, 27, 0.2),
    0px 2px 4px rgba(143, 101, 18, 0.2);
  --yellowShadow8dp: 0px 8px 16px rgba(214, 152, 27, 0.16),
    0px 4px 8px rgba(143, 101, 18, 0.16);
  --yellowShadow16dp: 0px 16px 32px rgba(214, 152, 27, 0.12),
    0px 8px 16px rgba(143, 101, 18, 0.12);
  --yellowShadow24dp: 0px 24px 48px rgba(214, 152, 27, 0.08),
    0px 12px 24px rgba(143, 101, 18, 0.08);

  --indigoShadow2dp: 0px 2px 4px rgba(92, 107, 192, 0.24),
    0px 1px 2px rgba(59, 69, 123, 0.24);
  --indigoShadow4dp: 0px 4px 8px rgba(92, 107, 192, 0.2),
    0px 2px 4px rgba(59, 69, 123, 0.2);
  --indigoShadow8dp: 0px 8px 16px rgba(92, 107, 192, 0.16),
    0px 4px 8px rgba(59, 69, 123, 0.16);
  --indigoShadow16dp: 0px 16px 32px rgba(92, 107, 192, 0.12),
    0px 8px 16px rgba(59, 69, 123, 0.12);
  --indigoShadow24dp: 0px 24px 48px rgba(92, 107, 192, 0.08),
    0px 12px 24px rgba(59, 69, 123, 0.08);

  --tealShadow2dp: 0px 2px 4px rgba(6, 150, 151, 0.24),
    0px 1px 2px rgba(4, 96, 97, 0.24);
  --tealShadow4dp: 0px 4px 8px rgba(6, 150, 151, 0.2),
    0px 2px 4px rgba(4, 96, 97, 0.2);
  --tealShadow8dp: 0px 8px 16px rgba(6, 150, 151, 0.16),
    0px 4px 8px rgba(4, 96, 97, 0.16);
  --tealShadow16dp: 0px 16px 32px rgba(6, 150, 151, 0.12),
    0px 8px 16px rgba(4, 96, 97, 0.12);
  --tealShadow24dp: 0px 24px 48px rgba(6, 150, 151, 0.08),
    0px 12px 24px rgba(4, 96, 97, 0.08);

  --orangeShadow2dp: 0px 2px 4px rgba(251, 130, 0, 0.24),
    0px 1px 2px rgba(160, 83, 0, 0.24);
  --orangeShadow4dp: 0px 4px 8px rgba(251, 130, 0, 0.2),
    0px 2px 4px rgba(160, 83, 0, 0.2);
  --orangeShadow8dp: 0px 8px 16px rgba(251, 130, 0, 0.16),
    0px 4px 8px rgba(160, 83, 0, 0.16);
  --orangeShadow16dp: 0px 16px 32px rgba(251, 130, 0, 0.12),
    0px 8px 16px rgba(160, 83, 0, 0.12);
  --orangeShadow24dp: 0px 24px 48px rgba(251, 130, 0, 0.08),
    0px 12px 24px rgba(160, 83, 0, 0.08);

  --pinkShadow2dp: 0px 2px 4px rgba(236, 64, 122, 0.24),
    0px 1px 2px rgba(151, 41, 78, 0.24);
  --pinkShadow4dp: 0px 4px 8px rgba(236, 64, 122, 0.2),
    0px 2px 4px rgba(151, 41, 78, 0.2);
  --pinkShadow8dp: 0px 8px 16px rgba(236, 64, 122, 0.16),
    0px 4px 8px rgba(151, 41, 78, 0.16);
  --pinkShadow16dp: 0px 16px 32px rgba(236, 64, 122, 0.12),
    0px 8px 16px rgba(151, 41, 78, 0.12);
  --pinkShadow24dp: 0px 24px 48px rgba(236, 64, 122, 0.08),
    0px 12px 24px rgba(151, 41, 78, 0.08);

  /*NEW GRADIENTS*/
  --gradBlue: var(--blue60) 0%, var(--indigo70) 100%;
  --gradBlueRev: var(--indigo70) 0%, var(--blue60) 100%;
  --gradRed: var(--red60) 0%, var(--pink70) 100%;
  --gradRedRev: var(--pink70) 0%, var(--red60) 100%;
  --gradGreen: var(--green60) 0%, var(--teal70) 100%;
  --gradGreenRev: var(--teal70) 0%, var(--green60) 100%;
  --gradYellow: var(--yellow60) 0%, var(--orange70) 100%;
  --gradYellowRev: var(--orange70) 0%, var(--yellow60) 100%;
  --gradGrey: var(--grey40) 0%, var(--grey70) 100%;
  --gradGreyRev: var(--grey70) 0%, var(--grey40) 100%;

  --gradIndigo: var(--indigo60) 0%, var(--blue70) 100%;
  --gradIndigoRev: var(--blue70) 0%, var(--indigo60) 100%;
  --gradPink: var(--pink60) 0%, var(--red70) 100%;
  --gradPinkRev: var(--red70) 0%, var(--pink60) 100%;
  --gradTeal: var(--teal60) 0%, var(--green70) 100%;
  --gradTealRev: var(--green70) 0%, var(--teal60) 100%;
  --gradOrange: var(--orange60) 0%, var(--yellow70) 100%;
  --gradOrangeRev: var(--yellow70) 0%, var(--orange60) 100%;

  /*Ligth*/
  --gradBlueL: var(--blue10) 0%, var(--indigo30) 100%;
  --gradBlueRevL: var(--indigo30) 0%, var(--blue10) 100%;
  --gradRedL: var(--red10) 0%, var(--pink30) 100%;
  --gradRedRevL: var(--pink30) 0%, var(--red10) 100%;
  --gradGreenL: var(--green10) 0%, var(--teal30) 100%;
  --gradGreenRevL: var(--teal30) 0%, var(--green10) 100%;
  --gradYellowL: var(--yellow10) 0%, var(--orange30) 100%;
  --gradYellowRevL: var(--orange30) 0%, var(--yellow10) 100%;
  --gradGreyL: var(--grey10) 0%, var(--grey40) 100%;
  --gradGreyRevL: var(--grey40) 0%, var(--grey10) 100%;
}

html,
body {
  font-size: 100%;
  /*font-size: var(--font-size-Body1);*/
  font-family: var(--font-primary);
  font-style: normal;
  line-height: normal;
  line-height: initial;
  letter-spacing: var(--letter-spacing-Body1);
  font-weight: var(--font-weight-Body1);
  color: var(--grey100);
}
button {
  font-family: var(--font-primary);
}

/*Remove blue background on click*/
input,
textarea,
button,
select,
label,
a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
/**/
* {
  -webkit-tap-highlight-color: transparent;
}

*:focus {
  outline: none;
  outline-offset: 0;
}

.bp3-control input:focus ~ .bp3-control-indicator {
  outline: none;
  outline-offset: 0;
}

.bp3-button:not([class*="bp3-intent-"]) {
  box-shadow: none;
  background-color: unset;
  background-image: none;
  color: unset;
}

.bp3-button:not([class*="bp3-intent-"]):hover {
  box-shadow: none;
  background-clip: unset;
  background-color: unset;
}

.bp3-button .bp3-icon {
  color: unset;
}

label.bp3-label {
  font-size: 1rem;
  font-weight: bold;
  text-align: left;
  text-align: initial;
  margin-bottom: 0.625rem;
  margin-right: 1rem;
}

@charset "UTF-8";

:root {
  --roboto: "Roboto", sans-serif;
  --quicksand: "Quicksand", sans-serif;
  --inter: "Inter", sans-serif;
  --plex: "IBM Plex Sans", sans-serif;
  --lato: "Lato", sans-serif;
  --manrope: "Manrope", sans-serif;

  /*Font sizes*/
  --font_size_h1: 6rem;
  --font_size_h2: 3.75rem;
  --font_size_h3: 3rem;
  --font_size_h4: 2.125rem;
  --font_size_h5: 1.5rem;
  --font_size_h6: 1.25rem;
  --font_size_body: 1rem;
  --font_size_body_large: 1.25rem;
  --font_size_body_small: 0.875rem;
  --font_size_button: 0.875rem;
  --font_size_caption: 0.75rem;
  --font_size_caption_small: 0.625rem;
  --font_size_overline: 0.75rem;
  --font_size_overline_small: 0.625rem;

  /*Font weight*/
  --font_weight_h1: 700;
  --font_weight_h2: 700;
  --font_weight_h3: 700;
  --font_weight_h4: 700;
  --font_weight_h5: 700;
  --font_weight_h6: 700;
  --font_weight_body: 400;
  --font_weight_body_medium: 500;
  --font_weight_button: 700;
  --font_weight_caption: 400;
  --font_weight_caption_small: 500;
  --font_weight_overline: 600;

  /*Letter spacing*/
  --letter_spacing_h1: -0.026rem;
  --letter_spacing_h2: -0.025rem;
  --letter_spacing_h3: -0.01rem;
  --letter_spacing_h4: 0;
  --letter_spacing_h5: 0;
  --letter_spacing_h6: 0.0075rem;
  --letter_spacing_body: 0.0275rem;
  --letter_spacing_body_bold: 0.022rem;
  --letter_spacing_body_large: 0.0075rem;
  --letter_spacing_body_large_bold: 0.004rem;
  --letter_spacing_body_small: 0.018rem;
  --letter_spacing_button: 0.054rem;
  --letter_spacing_caption: 0.033rem;
  --letter_spacing_caption_small: 0.06rem;
  --letter_spacing_overline: 0.0168rem;
  --letter_spacing_overline_small: 0.15rem;
}

.roboto {
  font-family: "Roboto", sans-serif;
  font-family: var(--roboto);
}
.quicksand {
  font-family: "Quicksand", sans-serif;
  font-family: var(--quicksand);
}
.inter {
  font-family: "Inter", sans-serif;
  font-family: var(--inter);
}
.lato {
  font-family: "Lato", sans-serif;
  font-family: var(--lato);
}
.plex {
  font-family: "IBM Plex Sans", sans-serif;
  font-family: var(--plex);
}
.monrope {
  font-family: "Manrope", sans-serif;
  font-family: var(--manrope);
}

.h1,
.H1 {
  font-weight: 700;
  font-weight: var(--font_weight_h1);
  font-size: 6rem;
  font-size: var(--font_size_h1);
  letter-spacing: -0.026rem;
  letter-spacing: var(--letter_spacing_h1);
}

.h2,
.H2 {
  font-weight: 700;
  font-weight: var(--font_weight_h2);
  font-size: 3.75rem;
  font-size: var(--font_size_h2);
  letter-spacing: -0.025rem;
  letter-spacing: var(--letter_spacing_h2);
}

.h3,
.H3 {
  font-weight: 700;
  font-weight: var(--font_weight_h3);
  font-size: 3rem;
  font-size: var(--font_size_h3);
  letter-spacing: -0.01rem;
  letter-spacing: var(--letter_spacing_h3);
}

.h4,
.H4 {
  font-weight: 700;
  font-weight: var(--font_weight_h4);
  font-size: 2.125rem;
  font-size: var(--font_size_h4);
  letter-spacing: 0;
  letter-spacing: var(--letter_spacing_h4);
}

.h5,
.H5 {
  font-weight: 700;
  font-weight: var(--font_weight_h5);
  font-size: 1.5rem;
  font-size: var(--font_size_h5);
  letter-spacing: 0;
  letter-spacing: var(--letter_spacing_h5);
}

.h6,
.H6 {
  font-weight: 700;
  font-weight: var(--font_weight_h6);
  font-size: 1.25rem;
  font-size: var(--font_size_h6);
  letter-spacing: 0.0075rem;
  letter-spacing: var(--letter_spacing_h6);
}

.body,
.BodyBig {
  font-weight: 400;
  font-weight: var(--font_weight_body);
  font-size: 1rem;
  font-size: var(--font_size_body);
  letter-spacing: 0.0275rem;
  letter-spacing: var(--letter_spacing_body);
}

.button_text {
  font-weight: 700;
  font-weight: var(--font_weight_button);
  font-size: 0.875rem;
  font-size: var(--font_size_button);
  letter-spacing: 0.054rem;
  letter-spacing: var(--letter_spacing_button);
}

.caption {
  font-weight: 400;
  font-weight: var(--font_weight_caption);
  font-size: 0.75rem;
  font-size: var(--font_size_caption);
  letter-spacing: 0.033rem;
  letter-spacing: var(--letter_spacing_caption);
}

.overline {
  font-weight: 600;
  font-weight: var(--font_weight_overline);
  font-size: 0.75rem;
  font-size: var(--font_size_overline);
  letter-spacing: 0.0168rem;
  letter-spacing: var(--letter_spacing_overline);
  text-transform: uppercase;
}

:root {
  --step: 0.25rem;
  /* BREAKPOINTS */
  --sm: 640px;
  --md: 768px;
  --lg: 1024px;
  --xl: 1280px;

  /* SIZE STEPS */
  --s0: 0;
  --s1px: 1px;
  --sAuto: auto;
  --s100vh: 100vh;
  --s100vw: 100vw;
  --sFull: 100%;

  /*Percents*/
  --s10p: 10%;
  --s20p: 20%;
  --s25p: 25%;
  --s30p: 30%;
  --s33p: 33.333333%;
  --s40p: 40%;
  --s50p: 50%;
  --s60p: 60%;
  --s66p: 66.666667%;
  --s70p: 70%;
  --s75p: 75%;
  --s80p: 80%;
  --s90p: 90%;

  /*Steps*/
  --s4: 0.25rem; /*4px*/
  --s8: 0.5rem; /*8px*/
  --s12: 0.75rem;
  --s16: 1rem;
  --s20: 1.25rem;
  --s24: 1.5rem;
  --s28: 1.75rem;
  --s32: 2rem;
  --s36: 2.25rem;
  --s40: 2.5rem;
  --s44: 2.75rem;
  --s48: 3rem;
  --s52: 3.25rem;
  --s56: 3.5rem;
  --s60: 3.75rem;
  --s64: 4rem;
  --s68: 4.25rem;
  --s72: 4.5rem;
  --s76: 4.75rem;
  --s80: 5rem;
  --s84: 5.25rem;
  --s88: 5.5rem;
  --s92: 5.75rem;
  --s96: 6rem;
  --s100: 6.25rem; /*100px*/
  --s128: 8rem;
  --s160: 10rem;
}

:root {
  /*Fonts*/
  --font-primary: var(--inter);
  --font_primary: var(--inter);

  --font-secondary: var(--inter); /** Used for captions */
  --font_secondary: var(--inter);

  --font-family-button: var(--inter);
  --font_family_button: var(--inter);
}

#root {
    min-width: 100%;
    min-height: 100%;
}

.sidebar {
    background: #ffffff;
    padding: 16px;
    width: 216px;
    box-shadow: var(--shadow4dp);
}

.content {
    background: linear-gradient(0, #FFFFFF 60%, #1C2D59 40%);
    padding: 0px 24px;
}

.navbar {
    background: #1C2D59;
    padding: 0px 24px;
}

.navbar-content {
    background: #1C2D59;
    height: 64px;
}

.navbar-header {
    font-size: 24px;
    color: #ffffff;
    font-weight: 600;
}

.splash-screen-container {
    width: 100vw;
    height: 100vh;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}

.splash-screen-content {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;

}

.splash-screen-content img {
    width: 96px;
    height: auto;
}
/**INPUT*/
._type_def__3L-xJ._type_fill__1RnD6,
._type_dense__1YR-d._type_fill__1RnD6 {
  width: 100%;
}
._type_def__3L-xJ,
._type_dense__1YR-d {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
._type_def__3L-xJ[class*="input-group"] > input[class*="input"] {
  height: 2.5rem;
  border-radius: 0.25rem;
  padding: 0 2.5rem !important;
  font-size: var(--font_size_body);
  letter-spacing: var(--letter_spacing_body);
  font-weight: var(--font_weight_body);
}
._type_dense__1YR-d[class*="input-group"] > input[class*="input"] {
  height: 1.75rem;
  border-radius: 0.25rem;
  padding: 0 1.75rem !important;
  font-size: var(--font_size_body_small);
  letter-spacing: var(--letter_spacing_body_small);
  font-weight: var(--font_weight_body_small);
}

._type_def__3L-xJ[class*="input-group"] > button[class*="button"],
._type_dense__1YR-d[class*="input-group"] > button[class*="button"],
._type_def__3L-xJ[class*="input-group"]
  span[class*="input-action"]
  > button[class*="button"],
._type_dense__1YR-d[class*="input-group"]
  span[class*="input-action"]
  > button[class*="button"] {
  z-index: 1;
  margin: 0;
  padding: 0;
  width: 1.25rem;
  height: 100%;
  min-width: 1.25rem;
  min-height: 0;
  margin-left: 0.75rem;
}

._type_def__3L-xJ[class*="input-group"] span[class*="input-action"],
._type_dense__1YR-d[class*="input-group"] span[class*="input-action"] {
  height: 100%;
}
._type_def__3L-xJ[class*="input-group"]
  span[class*="input-action"]
  > button[class*="button"] {
  margin-right: 0.625rem;
}
._type_dense__1YR-d[class*="input-group"] > button[class*="button"] {
  width: 1rem;
  min-width: 1rem;
  margin-left: 0.5rem;
}
._type_dense__1YR-d[class*="input-group"]
  span[class*="input-action"]
  > button[class*="button"] {
  margin-right: 0.375rem;
}

._type_def__3L-xJ[class*="active"] > input[class*="input"],
._type_dense__1YR-d[class*="active"] > input[class*="input"] {
  font-weight: var(--font_weight_body_medium);
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
}

/*INPUT*/
/*SMOOTH*/
._view_smooth__2CS_C[class*="input-group"] input[class*="input"] {
  box-shadow: none;
  background: var(--color5);
  color: var(--color100);
  border: none;
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
}
._view_smooth__2CS_C[class*="input-group"] input[class*="input"]:hover {
  box-shadow: none;
  background: var(--color10);
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
}
._view_smooth__2CS_C[class*="input-group"]._view_active__9wqyf input[class*="input"] {
  box-shadow: none;
  background: var(--color10);
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
}
._view_smooth__2CS_C[class*="input-group"] input[class*="input"]::-webkit-input-placeholder {
  color: var(--color40);
}
._view_smooth__2CS_C[class*="input-group"] input[class*="input"]:-ms-input-placeholder {
  color: var(--color40);
}
._view_smooth__2CS_C[class*="input-group"] input[class*="input"]::placeholder {
  color: var(--color40);
}
._view_smooth__2CS_C[class*="input-group"]:hover input[class*="input"]::-webkit-input-placeholder {
  color: var(--color60);
}
._view_smooth__2CS_C[class*="input-group"]:hover input[class*="input"]:-ms-input-placeholder {
  color: var(--color60);
}
._view_smooth__2CS_C[class*="input-group"]:hover input[class*="input"]::placeholder {
  color: var(--color60);
}
/**END SMOOTH*/

/*FILLED*/
._view_filled__qvDJD[class*="input-group"] input[class*="input"] {
  box-shadow: none;
  background: var(--color30);
  color: var(--color0);
  border: none;
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
}
._view_filled__qvDJD[class*="input-group"] input[class*="input"]:hover {
  box-shadow: none;
  background: var(--color40);
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
}
._view_filled__qvDJD[class*="input-group"]._view_active__9wqyf input[class*="input"] {
  box-shadow: none;
  background: var(--color70);
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
}
._view_filled__qvDJD[class*="input-group"] input[class*="input"]::-webkit-input-placeholder {
  color: var(--color0);
}
._view_filled__qvDJD[class*="input-group"] input[class*="input"]:-ms-input-placeholder {
  color: var(--color0);
}
._view_filled__qvDJD[class*="input-group"] input[class*="input"]::placeholder {
  color: var(--color0);
}

/**END FILLED*/

/*OUTLINED*/
._view_outlined__yzDrk[class*="input-group"] input[class*="input"] {
  box-shadow: 0 0 0 0.0625rem var(--color30);
  color: var(--color100);
  border: none;
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
}
._view_outlined__yzDrk[class*="input-group"] input[class*="input"]:hover {
  box-shadow: 0 0 0 0.0625rem var(--color80);
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
}
._view_outlined__yzDrk[class*="input-group"]._view_active__9wqyf input[class*="input"] {
  box-shadow: 0 0 0 0.125rem var(--color80);
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
}
._view_outlined__yzDrk[class*="input-group"] input[class*="input"]::-webkit-input-placeholder {
  color: var(--color40);
}
._view_outlined__yzDrk[class*="input-group"] input[class*="input"]:-ms-input-placeholder {
  color: var(--color40);
}
._view_outlined__yzDrk[class*="input-group"] input[class*="input"]::placeholder {
  color: var(--color40);
}
/**END OUTLINED*/

/*RAISED*/
._view_raised__151Oj[class*="input-group"] input[class*="input"] {
  box-shadow: var(--shadow2dp);
  color: var(--color100);
  border: none;
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
}
._view_raised__151Oj[class*="input-group"] input[class*="input"]:hover {
  box-shadow: var(--shadow4dp);
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
}
._view_raised__151Oj[class*="input-group"]._view_active__9wqyf input[class*="input"] {
  box-shadow: var(--shadow8dp);
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
}
._view_raised__151Oj[class*="input-group"] input[class*="input"]::-webkit-input-placeholder {
  color: var(--color40);
}
._view_raised__151Oj[class*="input-group"] input[class*="input"]:-ms-input-placeholder {
  color: var(--color40);
}
._view_raised__151Oj[class*="input-group"] input[class*="input"]::placeholder {
  color: var(--color40);
}
._view_raised__151Oj[class*="input-group"]:hover input[class*="input"]::-webkit-input-placeholder {
  color: var(--color60);
}
._view_raised__151Oj[class*="input-group"]:hover input[class*="input"]:-ms-input-placeholder {
  color: var(--color60);
}
._view_raised__151Oj[class*="input-group"]:hover input[class*="input"]::placeholder {
  color: var(--color60);
}
/**END RAISED*/

/*SEARCH BUTTON*/
div[class*="input-group"] > ._view_searchButton__2No8B {
  color: var(--color30);
}
div[class*="input-group"] > ._view_searchButton__2No8B:hover {
  color: var(--color50);
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
}
._view_smooth__2CS_C[class*="input-group"]._view_active__9wqyf > ._view_searchButton__2No8B {
  color: var(--color60);
}
._view_filled__qvDJD[class*="input-group"] > ._view_searchButton__2No8B,
._view_filled__qvDJD[class*="input-group"]._view_active__9wqyf > ._view_searchButton__2No8B:hover {
  color: var(--color20);
}
._view_filled__qvDJD[class*="input-group"]._view_active__9wqyf > ._view_searchButton__2No8B,
._view_filled__qvDJD[class*="input-group"] > ._view_searchButton__2No8B:hover {
  color: var(--color0);
}

/*-----*/
/*CLEAR BUTTON*/

div[class*="input-group"]
  > input[class*="input"]
  + span[class*="input-action"]
  > button[class*="minimal"]._view_clearButton__1f2Je:not(:hover):not(:focus)
  > span[class*="icon"]
  > svg {
  fill: var(--color30) !important;
}
div[class*="input-group"] span[class*="input-action"] > button {
  color: var(--color30) !important;
}
._view_smooth__2CS_C[class*="input-group"]
  > input[class*="input"]
  + span[class*="input-action"]
  > button[class*="minimal"]._view_clearButton__1f2Je:not(:hover):not(:focus)
  > span[class*="icon"]
  > svg,
._view_raised__151Oj[class*="input-group"]
  > input[class*="input"]
  + span[class*="input-action"]
  > button[class*="minimal"]._view_clearButton__1f2Je:not(:hover):not(:focus)
  > span[class*="icon"]
  > svg {
  fill: var(--color40) !important;
}
._view_smooth__2CS_C[class*="input-group"] span[class*="input-action"] > button,
._view_raised__151Oj[class*="input-group"] span[class*="input-action"] > button {
  color: var(--color40) !important;
}
._view_filled__qvDJD[class*="input-group"] span[class*="input-action"] > button {
  color: var(--color50) !important;
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
}
._view_filled__qvDJD[class*="input-group"]._view_active__9wqyf span[class*="input-action"] > button {
  color: var(--color40) !important;
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
}
._view_filled__qvDJD[class*="input-group"]
  > input[class*="input"]
  + span[class*="input-action"]
  > button[class*="minimal"]._view_clearButton__1f2Je:not(:hover):not(:focus)
  > span[class*="icon"]
  > svg {
  fill: var(--color40) !important;
}

div[class*="input-group"]._view_isFilled__EcthQ
  span[class*="input-action"]
  > button._view_clearButton__1f2Je {
  visibility: hidden;
  opacity: 0;
}
._view_div__117Mi[class*="input-group"]._view_isFilled__EcthQ span[class*="input-action"] > button {
  visibility: hidden;
  opacity: 0;
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
}
._view_div__117Mi[class*="input-group"]
  > input[class*="input"]
  + span[class*="input-action"]
  > button[class*="minimal"]._view_clearButton__1f2Je:not(:hover):not(:focus)
  > span[class*="icon"] {
  opacity: 1;
  visibility: visible;
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
}

div[class*="input-group"] span[class*="input-action"] > button {
  visibility: hidden;
}
div[class*="input-group"] span[class*="input-action"] > ._view_clearButton__1f2Je {
  visibility: visible;
}
div[class*="input-group"]._view_isFilled__EcthQ span[class*="input-action"] > ._view_clearButton__1f2Je {
  visibility: hidden;
  opacity: 0;
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
}
div[class*="input-group"]._view_isFilled__EcthQ:hover
  span[class*="input-action"]
  > ._view_clearButton__1f2Je {
  visibility: visible;
  opacity: 1;
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
}
/*-----*/

/* COLORS*/
:root {
  --color0: var(--grey0);
  --color5: var(--grey5);
  --color10: var(--grey10);
  --color20: var(--grey20);
  --color30: var(--grey30);
  --color40: var(--grey40);
  --color50: var(--grey50);
  --color60: var(--grey60);
  --color70: var(--grey70);
  --color80: var(--grey80);
  --color90: var(--grey90);
  --color100: var(--grey100);

  --shadow2dp: var(--greyShadow2dp);
  --shadow4dp: var(--greyShadow4dp);
  --shadow8dp: var(--greyShadow8dp);
  --shadow16dp: var(--greyShadow16dp);
  --shadow24dp: var(--greyShadow24dp);

  /*GRADIENTS*/
  --gradient: var(--gradGrey);
  --gradient-reverse: var(--gradGreyRev);
  /*-----------------*/

  /*BADGE*/
  --badge-def-bg: var(--color60);

  /*TABLE*/
  --flat-def-bg-table-hover: var(--color5);
  --flat-def-color-table: var(--color50);
  --flat-def-border-table: var(--color10);
  --flat-def-border2-table: var(--color50);
  --flat-def-borderlast-table: var(--color40);
  --flat-def-color-table-pag: var(--color60);
  --flat-def-color-checkbox: var(--color70);

  --filled-def-bg-table: var(--color40);
  --filled-def-bg-table-hover: var(--color50);
  --filled-def-bg-head-table: var(--color70);
  --filled-def-border-table: var(--color30);
  --filled-def-border2-table: var(--color10);
  --filled-def-head-table-sort: var(--color60);
  --filled-def-color-checkbox: var(--color20);

  --smooth-def-bg-head-table: var(--color10);
  --smooth-def-bg-table: var(--color0);
  --smooth-def-border-table: var(--color10);
  --smooth-def-border2-table: var(--color50);
  --smooth-def-color-table: var(--color50);
  --smooth-def-color-table-pag: var(--color60);

  --raised-def-border-table: var(--color10);
  --raised-def-border2-table: var(--color50);
  --raised-def-prim-color-table: var(--color50);
  /*-------------------*/
}

.color_select__2Ukks {
  /*BADGE*/
  --badge-bg: var(--color60);

  /*TABLE*/
  --flat-bg-table-hover: var(--color5);
  --flat-color-table: var(--color50);
  --flat-border-table: var(--color10);
  --flat-border2-table: var(--color50);
  --flat-borderlast-table: var(--color40);
  --flat-color-table-pag: var(--color60);
  --flat-color-checkbox: var(--color70);

  --filled-bg-table: var(--color40);
  --filled-bg-table-hover: var(--color50);
  --filled-bg-head-table: var(--color70);
  --filled-border-table: var(--color30);
  --filled-border2-table: var(--color10);
  --filled-head-table-sort: var(--color60);
  --filled-color-checkbox: var(--color20);

  --smooth-bg-head-table: var(--color10);
  --smooth-bg-table: var(--color0);
  --smooth-border-table: var(--color10);
  --smooth-border2-table: var(--color50);
  --smooth-color-table: var(--color50);
  --smooth-color-table-pag: var(--color60);

  --raised-border-table: var(--color10);
  --raised-border2-table: var(--color50);
  --raised-prim-color-table: var(--color50);
  /*-------------------*/
}
.color_primary__2JdEM {
  --color0: var(--blue0);
  --color5: var(--blue5);
  --color10: var(--blue10);
  --color20: var(--blue20);
  --color30: var(--blue30);
  --color40: var(--blue40);
  --color50: var(--blue50);
  --color60: var(--blue60);
  --color70: var(--blue70);
  --color80: var(--blue80);
  --color90: var(--blue90);
  --color100: var(--blue100);

  --shadow2dp: var(--blueShadow2dp);
  --shadow4dp: var(--blueShadow4dp);
  --shadow8dp: var(--blueShadow8dp);
  --shadow16dp: var(--blueShadow16dp);
  --shadow24dp: var(--blueShadow24dp);

  /*GRADIENTS*/
  --gradient: var(--gradBlue);
  --gradient-reverse: var(--gradBlueRev);
  /*-----*/
}

.color_danger__OZcfT {
  --color0: var(--red0);
  --color5: var(--red5);
  --color10: var(--red10);
  --color20: var(--red20);
  --color30: var(--red30);
  --color40: var(--red40);
  --color50: var(--red50);
  --color60: var(--red60);
  --color70: var(--red70);
  --color80: var(--red80);
  --color90: var(--red90);
  --color100: var(--red100);

  --shadow2dp: var(--redShadow2dp);
  --shadow4dp: var(--redShadow4dp);
  --shadow8dp: var(--redShadow8dp);
  --shadow16dp: var(--redShadow16dp);
  --shadow24dp: var(--redShadow24dp);

  /*GRADIENTS*/
  --gradient: var(--gradRed);
  --gradient-reverse: var(--gradRedRev);
  /*-----*/
}
.color_success__38Ixh {
  --color0: var(--green0);
  --color5: var(--green5);
  --color10: var(--green10);
  --color20: var(--green20);
  --color30: var(--green30);
  --color40: var(--green40);
  --color50: var(--green50);
  --color60: var(--green60);
  --color70: var(--green70);
  --color80: var(--green80);
  --color90: var(--green90);
  --color100: var(--green100);

  --shadow2dp: var(--greenShadow2dp);
  --shadow4dp: var(--greenShadow4dp);
  --shadow8dp: var(--greenShadow8dp);
  --shadow16dp: var(--greenShadow16dp);
  --shadow24dp: var(--greenShadow24dp);

  /*GRADIENTS*/
  --gradient: var(--gradGreen);
  --gradient-reverse: var(--gradGreenRev);
  /*-----------------*/
}
.color_warning__3ayA6 {
  --color0: var(--yellow0);
  --color5: var(--yellow5);
  --color10: var(--yellow10);
  --color20: var(--yellow20);
  --color30: var(--yellow30);
  --color40: var(--yellow40);
  --color50: var(--yellow50);
  --color60: var(--yellow60);
  --color70: var(--yellow70);
  --color80: var(--yellow80);
  --color90: var(--yellow90);
  --color100: var(--yellow100);

  --shadow2dp: var(--yellowShadow2dp);
  --shadow4dp: var(--yellowShadow4dp);
  --shadow8dp: var(--yellowShadow8dp);
  --shadow16dp: var(--yellowShadow16dp);
  --shadow24dp: var(--yellowShadow24dp);

  /*GRADIENTS*/
  --gradient: var(--gradYellow);
  --gradient-reverse: var(--gradYellowRev);
  /*-----------------*/
}
.color_default__1L5X6 {
  --color0: var(--grey0);
  --color5: var(--grey5);
  --color10: var(--grey10);
  --color20: var(--grey20);
  --color30: var(--grey30);
  --color40: var(--grey40);
  --color50: var(--grey50);
  --color60: var(--grey60);
  --color70: var(--grey70);
  --color80: var(--grey80);
  --color90: var(--grey90);
  --color100: var(--grey100);

  --shadow2dp: var(--greyShadow2dp);
  --shadow4dp: var(--greyShadow4dp);
  --shadow8dp: var(--greyShadow8dp);
  --shadow16dp: var(--greyShadow16dp);
  --shadow24dp: var(--greyShadow24dp);

  /*GRADIENTS*/
  --gradient: var(--gradGrey);
  --gradient-reverse: var(--gradGreyRev);
  /*-----------------*/
}

.color_primary_alt__2Xyjt {
  --color0: var(--indigo0);
  --color5: var(--indigo5);
  --color10: var(--indigo10);
  --color20: var(--indigo20);
  --color30: var(--indigo30);
  --color40: var(--indigo40);
  --color50: var(--indigo50);
  --color60: var(--indigo60);
  --color70: var(--indigo70);
  --color80: var(--indigo80);
  --color90: var(--indigo90);
  --color100: var(--indigo100);

  --shadow2dp: var(--indigoShadow2dp);
  --shadow4dp: var(--indigoShadow4dp);
  --shadow8dp: var(--indigoShadow8dp);
  --shadow16dp: var(--indigoShadow16dp);
  --shadow24dp: var(--indigoShadow24dp);

  /*GRADIENTS*/
  --gradient: var(--gradIndigo);
  --gradient-reverse: var(--gradIndigoRev);
  /*-----*/
}

.color_danger_alt__3xCMP {
  --color0: var(--pink0);
  --color5: var(--pink5);
  --color10: var(--pink10);
  --color20: var(--pink20);
  --color30: var(--pink30);
  --color40: var(--pink40);
  --color50: var(--pink50);
  --color60: var(--pink60);
  --color70: var(--pink70);
  --color80: var(--pink80);
  --color90: var(--pink90);
  --color100: var(--pink100);

  --shadow2dp: var(--pinkShadow2dp);
  --shadow4dp: var(--pinkShadow4dp);
  --shadow8dp: var(--pinkShadow8dp);
  --shadow16dp: var(--pinkShadow16dp);
  --shadow24dp: var(--pinkShadow24dp);

  /*GRADIENTS*/
  --gradient: var(--gradPink);
  --gradient-reverse: var(--gradPinkRev);
  /*-----*/
}
.color_success_alt__3YDAl {
  --color0: var(--teal0);
  --color5: var(--teal5);
  --color10: var(--teal10);
  --color20: var(--teal20);
  --color30: var(--teal30);
  --color40: var(--teal40);
  --color50: var(--teal50);
  --color60: var(--teal60);
  --color70: var(--teal70);
  --color80: var(--teal80);
  --color90: var(--teal90);
  --color100: var(--teal100);

  --shadow2dp: var(--tealShadow2dp);
  --shadow4dp: var(--tealShadow4dp);
  --shadow8dp: var(--tealShadow8dp);
  --shadow16dp: var(--tealShadow16dp);
  --shadow24dp: var(--tealShadow24dp);

  /*GRADIENTS*/
  --gradient: var(--gradTeal);
  --gradient-reverse: var(--gradTealRev);
  /*-----------------*/
}
.color_warning_alt__pnF7v {
  --color0: var(--orange0);
  --color5: var(--orange5);
  --color10: var(--orange10);
  --color20: var(--orange20);
  --color30: var(--orange30);
  --color40: var(--orange40);
  --color50: var(--orange50);
  --color60: var(--orange60);
  --color70: var(--orange70);
  --color80: var(--orange80);
  --color90: var(--orange90);
  --color100: var(--orange100);

  --shadow2dp: var(--orangeShadow2dp);
  --shadow4dp: var(--orangeShadow4dp);
  --shadow8dp: var(--orangeShadow8dp);
  --shadow16dp: var(--orangeShadow16dp);
  --shadow24dp: var(--orangeShadow24dp);

  /*GRADIENTS*/
  --gradient: var(--gradOrange);
  --gradient-reverse: var(--gradOrangeRev);
  /*-----------------*/
}

.color_gold__2QJCB /*color for badges*/
 {
  --badge-bg: linear-gradient(90deg, var(--gradYellow));
}

/* END COLORS*/

/* BUTTONS TYPES*/
/*--DEFAULT--*/
._type_default__2ZIda {
  font-size: var(--font_size_button);
  font-family: var(--font_family_button);
  font-weight: bold;
  text-align: center;
  letter-spacing: var(--letter-spacing-button);
  min-width: var(--s40);
  height: var(--s40);
  min-height: unset;
  padding: 0 1rem 0 1rem;
  line-height: var(--s16);
  border-radius: var(--s4);
  text-transform: capitalize;
}
._type_default__2ZIda._type_dense__1BwCu {
  font-weight: normal;
  letter-spacing: var(--letter_spacing_body_small);
  height: var(--s28);
  padding: 0 0.75rem 0 0.75rem;
}
/*--card--*/
._type_card__1fgPn {
  font-size: var(--font_size_caption);
  font-family: var(--font_family_button);
  line-height: var(--s16);
  text-align: center;
  letter-spacing: var(--letter_spacing_caption);
  text-transform: none;
  min-width: var(--s80);
  height: var(--s56);
  display: inline;
  display: initial;
  word-break: break-all;
  padding: var(--s8) 0;
  border-radius: var(--s4);
  text-transform: capitalize;
}
._type_card__1fgPn p {
  margin: 0;
}
._type_card__1fgPn > span {
  margin-right: 0;
}
._type_card__1fgPn > span[class*="icon"] {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: -webkit-min-content;
  width: min-content;
  margin-bottom: 0.25rem;
}
._type_card__1fgPn[class*="button"] > div[class*="spinner"] {
  position: static;
  position: initial;
  margin-top: 0.625rem;
}
._type_dense__1BwCu._type_card__1fgPn {
  min-width: var(--s56);
  height: var(--s40);
  padding: var(--s6) 0;
  font-size: var(--font_size_caption_small);
  letter-spacing: var(--letter_spacing_caption_small);
}
._type_dense__1BwCu._type_card__1fgPn > span[class*="icon"] {
  margin-bottom: 0;
}
._type_dense__1BwCu._type_card__1fgPn[class*="button"] > div[class*="spinner"] {
  margin-top: 0.375rem;
}
/*--circle--*/
._type_circle__39DY7 {
  height: var(--s56);
  width: var(--s56);
  border-radius: 99px;
}

._type_dense__1BwCu._type_circle__39DY7 {
  height: var(--s40);
  width: var(--s40);
}
/*--ACTION--*/
._type_action__2BPIB {
  font-family: var(--font_family_button);
  font-style: normal;
  font-weight: var(--font_weight_h6);
  font-size: var(--font_size_h6);
  letter-spacing: var(--letter_spacing_h6);
  height: var(--s56);
  border-radius: 99px;
  padding: 0.75rem 1rem;
  text-transform: capitalize;
}
._type_action__2BPIB span {
  margin: 0;
}
._type_action__2BPIB span:first-child[class*="icon"] {
  text-align: left;
  margin-right: 0.75rem;
}
._type_action__2BPIB span:last-child[class*="icon"] {
  margin-left: 0.75rem;
}
._type_dense__1BwCu._type_action__2BPIB {
  font-weight: normal;
  font-size: var(--font_size_body);
  letter-spacing: var(--letter_spacing_body_bold);
  height: var(--s40);
  border-radius: 99px;
  padding: 0.5rem 0.75rem;
}

._type_dense__1BwCu._type_action__2BPIB span:first-child[class*="icon"] {
  margin-right: 0.625rem;
}
._type_dense__1BwCu._type_action__2BPIB span:last-child[class*="icon"] {
  margin-left: 0.625rem;
}
/*--ICON--*/
._type_icon__1SeW9 {
  width: var(--s36);
  height: var(--s36);
  min-width: 0;
  min-height: 0;
  border-radius: 99px;
  padding: 0;
}
._type_dense__1BwCu._type_icon__1SeW9 {
  width: var(--s28);
  height: var(--s28);
}

/*FLAT STYLE*/
._view_flat__1MvlD,
._view_flat__1MvlD[class*="button"],
._view_flat__1MvlD:visited {
  color: var(--color70);
  background: none;
  box-shadow: none;
  transition: all 0.3s cubic-bezier(0.55, 0.085, 0.68, 0.53);
}
._view_flat__1MvlD:hover,
._view_flat__1MvlD[class*="button"]:hover,
._view_flat__1MvlD:hover {
  box-shadow: var(--shadow2dp);
  background: none;
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
._view_flat__1MvlD:active,
._view_flat__1MvlD[class*="button"]:active,
._view_flat__1MvlD[class*="button"][class*="active"],
._view_flat__1MvlD[class*="button"]._view_focused__2-SCh {
  background: var(--color5);
  box-shadow: none;
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
._view_flat__1MvlD[class*="disabled"]:disabled,
._view_flat__1MvlD[class*="disabled"]:disabled > span > svg,
._view_flat__1MvlD[class*="disabled"]:disabled:hover,
._view_flat__1MvlD[class*="disabled"]:disabled:active {
  color: var(--color30);
  opacity: 1;
  background-color: var(--color0);
  box-shadow: none;
}
/*END STYLE*/

/* FILLED STYLE*/
._view_filled__3oNxN[class*="button"],
._view_filled__3oNxN[class*="button"]:visited {
  box-shadow: var(--shadow4dp);
  background: var(--color60);
  color: var(--color0);
  transition: all 0.3s cubic-bezier(0.55, 0.085, 0.68, 0.53);
}
._view_filled__3oNxN[class*="button"]:hover {
  background: var(--color70);
  box-shadow: var(--shadow8dp);
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
._view_filled__3oNxN[class*="button"]:active,
._view_filled__3oNxN[class*="button"]._view_focused__2-SCh {
  background: var(--color80);
  box-shadow: none;
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
._view_filled__3oNxN[class*="disabled"]:disabled {
  color: var(--color40);
  background-color: var(--color5);
  box-shadow: none;
  opacity: 1;
}
/* END STYLE */

/*SMOOTH STYLE*/
._view_smooth__rbzq8[class*="button"],
._view_smooth__rbzq8[class*="button"]:visited {
  color: var(--color70);
  background: var(--color5);
  box-shadow: none;
  transition: all 0.3s cubic-bezier(0.55, 0.085, 0.68, 0.53);
}
._view_smooth__rbzq8[class*="button"]:hover {
  color: var(--color100);
  background: var(--color10);
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
._view_smooth__rbzq8[class*="button"]:active,
._view_smooth__rbzq8[class*="button"]:active,
._view_smooth__rbzq8[class*="button"][class*="active"],
._view_smooth__rbzq8[class*="button"]._view_focused__2-SCh {
  background: var(--color20);
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
._view_smooth__rbzq8[class*="disabled"]:disabled,
._view_smooth__rbzq8[class*="disabled"]:disabled:hover {
  color: var(--color30);
  background-color: var(--color0);
  opacity: 1;
}
/* END STYLE */

/* OUTLINED STYLE*/
._view_outlined__2ENQg[class*="button"],
._view_outlined__2ENQg[class*="button"]:visited {
  background: none;
  box-shadow: 0 0 0 1px var(--color30); /*border: 1px solid var(--color30);*/
  color: var(--color70);
  transition: all 0.3s cubic-bezier(0.55, 0.085, 0.68, 0.53);
}
._view_outlined__2ENQg[class*="button"]:hover {
  box-shadow: 0 0 0 2px var(--color30);
  width: calc(- 2px);
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
._view_outlined__2ENQg[class*="button"]:active,
._view_outlined__2ENQg[class*="button"]._view_focused__2-SCh {
  color: var(--color100);
  background-color: var(--color5);
  box-shadow: 0 0 0 2px var(--color50);
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
._view_outlined__2ENQg[class*="disabled"]:disabled {
  background-color: unset;
  color: var(--color30);
  box-shadow: 0 0 0 1px var(--color10);
}
/* END STYLE */

/* RAISED STYLE */
._view_raised__1L_nJ[class*="button"],
._view_raised__1L_nJ[class*="button"]:visited {
  background: var(--color0);
  color: var(--color70);
  box-shadow: var(--shadow4dp);
  transition: all 0.3s cubic-bezier(0.55, 0.085, 0.68, 0.53);
}
._view_raised__1L_nJ[class*="button"]:hover {
  background: var(--white);
  color: var(--color100);
  box-shadow: var(--shadow8dp);
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
._view_raised__1L_nJ[class*="button"]:active,
._view_raised__1L_nJ[class*="button"]._view_focused__2-SCh {
  box-shadow: var(--shadow2dp);
  background-color: var(--white);
  color: var(--color70);
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
._view_raised__1L_nJ[class*="disabled"]:disabled {
  color: var(--color40);
  background-color: var(--color5);
  box-shadow: none;
}
/* END STYLE */

/**NAVBAR*/
div[class*="align-left"] {
  margin-right: auto;
}
div[class*="navbar-heading"] {
  margin-right: auto;
}
._type_desktop__3I4ll {
  display: -webkit-flex;
  display: flex;
  height: 4rem;
  border-radius: 0;
  /* padding: 0 1rem; */
}
._type_desktop__3I4ll div[class*="navbar-group"] {
  height: 4rem;
}

._type_tablet__2oPNj {
  display: -webkit-flex;
  display: flex;
  height: 3.5rem;
  border-radius: 0;
  padding: 0 1rem;
}
._type_tablet__2oPNj div[class*="navbar-group"] {
  height: 3.5rem;
}

._type_mobile__3C6Sm {
  height: 6rem;
  border-radius: 0;
  padding: 0.625rem 0.5rem;
}

/*GROUP ALIGN CENTER*/
._type_center__2zAS7 {
  -webkit-align-content: space-between;
          align-content: space-between;
  /* display: flex; */
  margin-left: auto;
  margin-right: auto;
}
._type_center__2zAS7 a:not(:first-of-type) {
  text-decoration: none;
  padding-left: 0.75rem;
}
._type_mobile__3C6Sm div[class*="navbar-group"] {
  height: auto;
  height: initial;
}
._type_mobile__3C6Sm div[class*="center"] {
  position: absolute;
  top: 100%;
  left: 0;
  margin-right: 0;
  -webkit-transform: translate(0%, -100%);
          transform: translate(0%, -100%);
  /*overflow-x: auto;*/
  width: 100%;
  overflow-y: hidden;
  padding: 0 0.5rem 0.625rem 0.5rem;
}

/**/

/*NAVBAR*/
._view_flat__1AqiX {
  box-shadow: none;
  background: "#1C2D59";
}
._view_smooth__ELTWI {
  box-shadow: none;
  background: var(--color5);
}
._view_raised__1TrKd {
  box-shadow: var(--shadow2dp);
  background: var(--white);
}
._view_filled__HghW- {
  box-shadow: var(--shadow2dp);
  background: var(--color70);
}

/* PROGRESS BAR TYPES*/
._type_def__hFu3X /*default*/
	 {
}

/*PROGRESS BAR*/

._view_filled__1Gz9q {
  background:var(--color10);
}

._view_filled__1Gz9q > div[class*="progress-meter"] {
  background-color: var(--color60);
}

/* TYPOGRAPHY TYPES*/

._type_roboto__3hPPJ {
  font-family: var(--roboto);
}
._type_inter__933qF {
  font-family: var(--inter);
}
._type_lato__33QXd {
  font-family: var(--lato);
}
._type_plex__39PIE {
  font-family: var(--plex);
}
._type_manrope__cnMpX {
  font-family: var(--manrope);
}

._type_h1__2ozoB {
  font-weight: var(--font_weight_h1);
  font-size: var(--font_size_h1);
  letter-spacing: var(--letter_spacing_h1);
  line-height: 120%;
}

._type_h2__1px5R {
  font-weight: var(--font_weight_h2);
  font-size: var(--font_size_h2);
  letter-spacing: var(--letter_spacing_h2);
  line-height: 120%;
}

._type_h3__6euOT {
  font-weight: var(--font_weight_h3);
  font-size: var(--font_size_h3);
  letter-spacing: var(--letter_spacing_h3);
  line-height: 124%;
}

._type_h4__1BFL5 {
  font-weight: var(--font_weight_h4);
  font-size: var(--font_size_h4);
  letter-spacing: var(--letter_spacing_h4);
  line-height: 128%;
}

._type_h5__17ZfI {
  font-weight: var(--font_weight_h5);
  font-size: var(--font_size_h5);
  letter-spacing: var(--letter_spacing_h5);
  line-height: 150%;
}

._type_h6__X37p3 {
  font-weight: var(--font_weight_h6);
  font-size: var(--font_size_h6);
  letter-spacing: var(--letter_spacing_h6);
  line-height: 150%;
}

._type_body__2tQqr {
  font-weight: var(--font_weight_body);
  font-size: var(--font_size_body);
  letter-spacing: var(--letter_spacing_body);
  line-height: 150%;
}
._type_body_bold__3BXPu {
  font-weight: var(--font_weight_body_medium);
  letter-spacing: var(--letter_spacing_body_bold);
}
._type_body__2tQqr._type_large__vkfCl {
  font-size: var(--font_size_body_large);
  letter-spacing: var(--letter_spacing_body_large);
  line-height: 158%;
}
._type_body_bold__3BXPu._type_large__vkfCl {
  font-size: var(--font_size_body_large);
  letter-spacing: var(--letter_spacing_body_large_bold);
  line-height: 158%;
}
._type_body__2tQqr._type_small__2U3FK,
._type_body_bold__3BXPu._type_small__2U3FK {
  font-size: var(--font_size_body_small);
  letter-spacing: var(--letter_spacing_body_small);
  line-height: 143%;
}
._type_button_text__3Ym4O {
  font-weight: var(--font_weight_button);
  font-size: var(--font_size_button);
  letter-spacing: var(--letter_spacing_button);
  line-height: 114%;
}

._type_caption__12WYh {
  font-weight: var(--font_weight_caption);
  font-size: var(--font_size_caption);
  letter-spacing: var(--letter_spacing_caption);
  line-height: 126%;
}
._type_caption__12WYh._type_small__2U3FK {
  font-size: var(--font_size_caption_small);
  font-weight: var(--font_weight_caption_small);
  letter-spacing: var(--letter_spacing_caption_small);
  line-height: 120%;
}

._type_overline__3G6mT {
  font-weight: var(--font_weight_overline);
  font-size: var(--font_size_overline);
  letter-spacing: var(--letter_spacing_overline);
  text-transform: uppercase;
  line-height: 126%;
}
._type_overline__3G6mT._type_small__2U3FK {
  font-size: var(--font_size_overline_small);
  letter-spacing: var(--letter_spacing_overline_small);
  line-height: 120%;
}

._view_color0__tNEVP {
  color: var(--color0);
}
._view_color5__1EzAy {
  color: var(--color5);
}
._view_color10__b1LBY {
  color: var(--color10);
}
._view_color20__13hIu {
  color: var(--color20);
}
._view_color30__1rTDW {
  color: var(--color30);
}
._view_color40__3fUfS {
  color: var(--color40);
}
._view_color50__3qqHG {
  color: var(--color50);
}
._view_color60__2y3mK {
  color: var(--color60);
}
._view_color70__1PweD {
  color: var(--color70);
}
._view_color80__2T9KB {
  color: var(--color80);
}
._view_color90__2zqIx {
  color: var(--color90);
}
._view_color100__3FuQ4 {
  color: var(--color100);
}

/*MENU SHAPE CSS MODULE*/
/*DEFAULT*/
._type_def__1F1S9,
._type_def__1F1S9 div[class*="popover-content"] > ul[class*="menu"] {
  border-radius: 0.5rem;
  margin: 0;
  padding: 0;
  font-size: var(--font_size_body);
  letter-spacing: var(--letter_spacing_body);
}
._type_def__1F1S9 div[class*="popover-content"] {
  border-radius: 0.5rem;
}
._type_def__1F1S9 > li:not([class*="menu-divider"]),
._type_def__1F1S9
  div[class*="popover-content"]
  > ul[class*="menu"]
  > li:not([class*="menu-divider"]) {
  height: 2.5rem;
  margin: 0;
}
._type_def__1F1S9 > li:first-of-type,
._type_def__1F1S9 div[class*="popover-content"] > ul[class*="menu"] > li:first-of-type {
  padding-top: 0.5rem;
  line-height: 3rem;
  height: 3rem;
}
._type_def__1F1S9 > li:last-of-type,
._type_def__1F1S9 div[class*="popover-content"] > ul[class*="menu"] > li:last-of-type {
  padding-bottom: 0.5rem;
  height: 3rem;
}
._type_def__1F1S9 div[class*="popover"][class*="submenu"],
._type_dense__2xVyj div[class*="popover"][class*="submenu"] {
  padding: 0;
  margin-left: -0.0625rem;
}
._type_def__1F1S9 div[class*="popover"][class*="submenu"],
._type_dense__2xVyj div[class*="popover"][class*="submenu"] {
  -webkit-animation: _type_slide-fwd-right__F5iAY 0.28s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
          animation: _type_slide-fwd-right__F5iAY 0.28s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

/*Heading divider*/
._type_def__1F1S9 > li[class*="menu-header"]:not([class*="menu-divider"]),
._type_dense__2xVyj > li[class*="menu-header"]:not([class*="menu-divider"]) {
  margin-top: 0.5rem;
}
/*----*/

/*DENSE*/
._type_dense__2xVyj,
._type_dense__2xVyj div[class*="popover-content"] > ul[class*="menu"] {
  border-radius: 0.5rem;
  margin: 0;
  padding: 0;
  font-size: var(--font_size_body_small);
  letter-spacing: var(--letter_spacing_body_small);
}
._type_dense__2xVyj div[class*="popover-content"] {
  border-radius: 0.5rem;
}
._type_dense__2xVyj > li:not([class*="menu-divider"]),
._type_dense__2xVyj
  div[class*="popover-content"]
  > ul[class*="menu"]
  > li:not([class*="menu-divider"]) {
  height: 1.75rem;
  margin: 0;
}
._type_dense__2xVyj > li:first-of-type,
._type_dense__2xVyj div[class*="popover-content"] > ul[class*="menu"] > li:first-of-type {
  padding-top: 0.5rem;
  line-height: 2.25rem;
  height: 2.25rem;
}
._type_dense__2xVyj > li:last-of-type,
._type_dense__2xVyj div[class*="popover-content"] > ul[class*="menu"] > li:last-of-type {
  padding-bottom: 0.5rem;
  height: 2.25rem;
}

/*-------------------*/

@-webkit-keyframes _type_slide-fwd-right__F5iAY {
  0% {
    -webkit-transform: scale(0.9) translateX(-20%);
            transform: scale(0.9) translateX(-20%);
  }
  100% {
    -webkit-transform: scale(1) translateX(0);
            transform: scale(1) translateX(0);
  }
}

@keyframes _type_slide-fwd-right__F5iAY {
  0% {
    -webkit-transform: scale(0.9) translateX(-20%);
            transform: scale(0.9) translateX(-20%);
  }
  100% {
    -webkit-transform: scale(1) translateX(0);
            transform: scale(1) translateX(0);
  }
}

/*MENU VIEW*/
/*SMOOTH*/
._view_smooth__1Sbcb,
._view_smooth__1Sbcb div[class*="popover-content"] > ul[class*="menu"] {
  box-shadow: var(--shadow2dp);
  color: var(--color100);
  background: var(--color0);
}
._view_smooth__1Sbcb div[class*="popover"] > div[class*="popover-content"] {
  box-shadow: none;
}
._view_smooth__1Sbcb svg {
  color: var(--color70);
}
._view_smooth__1Sbcb > li:not([class*="menu-divider"]) a {
  background-color: unset;
}
._view_smooth__1Sbcb > li:not([class*="menu-divider"]) a:hover:not([class*="disabled"]) {
  background-color: var(--white);
}
._view_smooth__1Sbcb a[class*="disabled"] > div,
._view_smooth__1Sbcb a > span[class*="label"],
._view_smooth__1Sbcb a[class*="disabled"] > span[class*="item-label"],
._view_smooth__1Sbcb a[class*="disabled"] svg,
._view_smooth__1Sbcb a > span[class*="label"] > span > svg {
  color: var(--color40) !important;
}
._view_smooth__1Sbcb
  > li[class*="submenu"]
  span[class*="popover-wrapper"]
  > span[class*="popover-open"]
  > a:not(:hover) {
  background-color: var(--color5);
}

/*OUTLINED*/
._view_outlined__1yJsb,
._view_outlined__1yJsb div[class*="popover-content"] > ul[class*="menu"] {
  box-shadow: none;
  color: var(--color100);
  background: var(--white);
  border: 0.125rem solid var(--color30);
}
._view_outlined__1yJsb div[class*="popover"] > div[class*="popover-content"] {
  box-shadow: none;
}
._view_outlined__1yJsb svg {
  color: var(--color70);
}
._view_outlined__1yJsb > li:not([class*="menu-divider"]) a {
  background-color: unset;
}
._view_outlined__1yJsb > li:not([class*="menu-divider"]) a:hover:not([class*="disabled"]) {
  background-color: var(--color5);
}
._view_outlined__1yJsb a[class*="disabled"] > div,
._view_outlined__1yJsb a > span[class*="label"],
._view_outlined__1yJsb a[class*="disabled"] > span[class*="item-label"],
._view_outlined__1yJsb a[class*="disabled"] svg,
._view_outlined__1yJsb a > span[class*="label"] > span > svg {
  color: var(--color40) !important;
}
._view_outlined__1yJsb
  > li[class*="submenu"]
  span[class*="popover-wrapper"]
  > span[class*="popover-open"]
  > a:not(:hover) {
  background-color: var(--color5);
}
/*-----*/
/*RAISED*/
._view_raised__d5hfP,
._view_raised__d5hfP div[class*="popover-content"] > ul[class*="menu"] {
  box-shadow: var(--shadow8dp);
  color: var(--color100);
  background: var(--white);
}
._view_raised__d5hfP div[class*="popover"] > div[class*="popover-content"] {
  box-shadow: none;
}
._view_raised__d5hfP svg {
  color: var(--color70);
}
._view_raised__d5hfP > li:not([class*="menu-divider"]) a {
  background-color: unset;
}
._view_raised__d5hfP > li:not([class*="menu-divider"]) a:hover:not([class*="disabled"]) {
  background-color: var(--color5);
}
._view_raised__d5hfP a[class*="disabled"] > div,
._view_raised__d5hfP a > span[class*="label"],
._view_raised__d5hfP a[class*="disabled"] > span[class*="item-label"],
._view_raised__d5hfP a[class*="disabled"] svg,
._view_raised__d5hfP a > span[class*="label"] > span > svg {
  color: var(--color40) !important;
}
._view_raised__d5hfP
  > li[class*="submenu"]
  span[class*="popover-wrapper"]
  > span[class*="popover-open"]
  > a:not(:hover) {
  background-color: var(--color5);
}
/*-----*/

/*MENU ITEM CSS MODULE*/
/*DEFAULT*/
._type_def__2-GZJ,
._type_dense__jekRO {
  line-height: 2.5rem;
  padding: 0;
  margin: 0 0.5rem;
  border-radius: 0.25rem;
}
._type_def__2-GZJ > div[class*="fill"] {
  margin-right: 0.75rem;
  margin-left: 0.75rem;
}
._type_def__2-GZJ > span[class*="icon"],
._type_dense__jekRO > span[class*="icon"] {
  margin-bottom: auto;
  margin-top: auto;
  margin-left: 0.75rem;
  margin-right: -0.25rem;
}

._type_def__2-GZJ > span[class*="menu-item-label"],
._type_dense__jekRO > span[class*="menu-item-label"] {
  margin-bottom: auto;
  margin-top: auto;
  margin-right: 0.75rem;
  margin-left: 0.75rem;
}
._type_def__2-GZJ > span[class*="icon"] > svg {
  width: 1.25rem;
  height: 1.25rem;
}
._type_def__2-GZJ > span[class*="menu-item-label"] > span[class*="icon"],
._type_dense__jekRO > span[class*="menu-item-label"] > span[class*="icon"] {
  display: block;
}

/*DENSE*/
._type_dense__jekRO {
  line-height: 1.75rem;
}
._type_dense__jekRO > div[class*="fill"] {
  margin-right: 0.5rem;
  margin-left: 0.5rem;
}
._type_dense__jekRO > span[class*="icon"] {
  margin-left: 0.5rem;
  margin-right: -0.125rem;
}
._type_dense__jekRO > span[class*="menu-item-label"] {
  margin-right: 0.5rem;
  margin-left: 0.5rem;
}
/*--------------*/
._type_def__2-GZJ > span[class*="icon-caret-right"],
._type_dense__jekRO > span[class*="icon-caret-right"] {
  margin-right: 0.5rem;
}

/*MENU ITEM*/
._view_smooth__3CTGd._view_note__1qF1H,
._view_outlined__8DCh-._view_note__1qF1H,
._view_raised__3sTcS._view_note__1qF1H {
  color: var(--color80);
}

/*CHECKBOX SHAPE CSS MODULE*/

/*CONTAINER*/
._type_container__3Xvis {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  box-shadow: none;
  padding: 0;
  margin: 0;
  padding-top: 5px;
  padding-bottom: 5px;
}
._type_container__3Xvis label[class*='control'] {
  display: -webkit-flex;
  display: flex;
  padding: 0;
  margin: 0;
}
._type_container__3Xvis._type_fill__CMl0v {
  width: 100%;
}
._type_box_layout__1o13- {
  position: absolute;
  border-radius: 0.25rem;
}
._type_text__12SGO {
  width: 100%;
}
/*to RIGHT*/
._type_container__3Xvis label[class*='control']._type_right__JSlte {
  -webkit-flex-direction: row-reverse;
          flex-direction: row-reverse;
}
/*---------------------------------------*/

/*DEFAULT*/
._type_def__1Mlnl {
  font-size: var(--font_size_body);
  letter-spacing: var(--letter_spacing_body);
  min-height: 2.5rem;
  line-height: 2.5rem;
  border-radius: 0.25rem;
}
._type_def__1Mlnl._type_with_helper__3cIWg {
  line-height: 2.75rem;
}
._type_def__1Mlnl ._type_box_layout__1o13- {
  width: 2.5rem;
  height: 2.5rem;
}
._type_def__1Mlnl._type_with_helper__3cIWg ._type_box_layout__1o13- {
  top: 0.125rem;
}
._type_def__1Mlnl ._type_text__12SGO {
  margin-left: 0.5rem;
  margin-right: 0.75rem;
}
._type_def__1Mlnl[class*='control'] span[class*='control-indicator'],
._type_def__1Mlnl[class*='control'] span[class*='control-indicator']::before {
  padding: 0;
  margin: auto 0.6875rem;
  width: 1.125rem;
  height: 1.125rem;
  border-radius: 0.125rem;
  z-index: 1;
}
._type_def__1Mlnl._type_checked__3qwG8,
._type_dense__3lB1g._type_checked__3qwG8 {
  font-weight: var(--font_weight_body_medium);
  transition: all 100ms cubic-bezier(0.4, 0, 0.2, 1);
}
._type_def__1Mlnl._type_checked__3qwG8 input:checked ~ span[class*='control-indicator']::before {
  margin: auto;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3e%3cpath  d='M4.1,12.7 9,17.6 20.3,6.3' fill='none' stroke-width ='2px' stroke='white'/%3e%3c/svg%3e");
}
._type_def__1Mlnl._type_checked__3qwG8 input:indeterminate ~ span[class*='control-indicator']::before,
._type_def__1Mlnl input:indeterminate ~ span[class*='control-indicator']::before {
  margin: auto;
}
/**-------------------------*/

/*DENSE*/
._type_dense__3lB1g {
  font-size: var(--font_size_body_small);
  letter-spacing: var(--letter_spacing_body_small);
  height: 1.75rem;
  line-height: 1.75rem;
  border-radius: 0.25rem;
}
._type_dense__3lB1g._type_with_helper__3cIWg {
  height: 2.5rem;
  line-height: 2.5rem;
}
._type_dense__3lB1g ._type_box_layout__1o13- {
  width: 1.75rem;
  height: 1.75rem;
}
._type_dense__3lB1g._type_with_helper__3cIWg ._type_box_layout__1o13- {
  top: 0.375rem;
}
._type_dense__3lB1g ._type_text__12SGO {
  margin-left: 0.375rem;
  margin-right: 0.5rem;
}
._type_dense__3lB1g[class*='control'] span[class*='control-indicator'],
._type_dense__3lB1g[class*='control'] span[class*='control-indicator']:before {
  padding: 0;
  margin: auto 0.40625rem;
  width: 0.9375rem;
  height: 0.9375rem;
  border-radius: 0.125rem;
  z-index: 1;
}
._type_dense__3lB1g._type_checked__3qwG8 input:checked ~ span::before {
  margin: auto;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3e%3cpath  d='M4.1,12.7 9,17.6 20.3,6.3' fill='none' stroke-width ='2px' stroke='white'/%3e%3c/svg%3e") !important;
}
._type_dense__3lB1g._type_checked__3qwG8 input:indeterminate ~ span[class*='control-indicator']::before,
._type_dense__3lB1g input:indeterminate ~ span[class*='control-indicator']::before {
  margin: auto;
}
/**--------------------------*/

/*HELPER TEXT*/
._type_helperBox__1dvod {
  line-height: normal;
  line-height: initial;
  margin-top: auto;
  margin-bottom: auto;
}
._type_helperBox__1dvod > ._type_helperText__3wFXI {
  font-size: var(--font_size_body_small);
  letter-spacing: var(--letter_spacing_body_small);
  font-weight: normal;
}
/*--------------------------------------------*/

/*RTL*/
._type_rtl__3btBD {
  text-align: right;
}
/*----------------------------------*/

/*FLAT*/
._view_flat__NmEIL {
  color: var(--color100);
}
._view_flat__NmEIL ._view_box_layout__lgRqQ {
  background: transparent;
  transition: all 100ms cubic-bezier(0.4, 0, 0.2, 1);
}
._view_flat__NmEIL:hover ._view_box_layout__lgRqQ {
  background: var(--color5);
  transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1);
}
._view_flat__NmEIL[class*="control"] span[class*="control-indicator"] {
  background-color: transparent !important;
  background-image: none;
  box-shadow: none !important;
  border: 2px solid var(--color70);
}
._view_flat__NmEIL._view_checked__I7yz- {
  background: var(--color5);
}
._view_flat__NmEIL._view_checked__I7yz- span[class*="control-indicator"] {
  border: none;
  box-shadow: none !important;
  background-image: none;
  background-color: var(--color70) !important;
}
._view_flat__NmEIL._view_checked__I7yz- input:checked ~ span::before,
._view_flat__NmEIL._view_checked__I7yz-._view_indeterminate__1XXIW span[class*="control-indicator"]::before {
  opacity: 1;
  transition: opacity linear 180ms;
}
._view_flat__NmEIL:hover input:checked ~ span[class*="control-indicator"] {
  background-color: var(--color70);
  box-shadow: none;
}
._view_flat__NmEIL input:not(:checked) ~ span[class*="control-indicator"]::before {
  opacity: 0;
  transition: opacity linear 180ms;
}
/*disabled*/
._view_flat__NmEIL._view_disabled__1oiX- {
  color: var(--color40) !important;
}
._view_flat__NmEIL._view_checked__I7yz-._view_disabled__1oiX- span[class*="control-indicator"] {
  background-color: var(--color40) !important;
}
._view_flat__NmEIL._view_disabled__1oiX- span[class*="control-indicator"] {
  border-color: var(--color40) !important;
}
._view_flat__NmEIL._view_disabled__1oiX-._view_checked__I7yz- span {
  border: none !important;
  background-color: none !important;
}
._view_flat__NmEIL._view_disabled__1oiX-:hover input:checked ~ span {
  background-color: none !important;
}
._view_flat__NmEIL._view_disabled__1oiX-:hover ._view_box_layout__lgRqQ,
._view_flat__NmEIL._view_checked__I7yz-._view_disabled__1oiX- {
  background: transparent;
}
/*helper*/
._view_flat__NmEIL ._view_helperText__U5wl1,
._view_flat__NmEIL._view_disabled__1oiX- ._view_helperText__U5wl1 {
  color: var(--color60);
}
/*--------------------------------*/
/*SMOOTH*/
._view_smooth__3aAS6 {
  color: var(--color100);
  background: var(--color0);
}
._view_smooth__3aAS6 ._view_box_layout__lgRqQ {
  background: transparent;
  transition: all 100ms cubic-bezier(0.4, 0, 0.2, 1);
}
._view_smooth__3aAS6:hover {
  background: var(--color5);
  transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1);
}
._view_smooth__3aAS6[class*="control"] span[class*="control-indicator"] {
  background-color: transparent !important;
  background-image: none;
  box-shadow: none !important;
  border: 2px solid var(--color70);
}
._view_smooth__3aAS6._view_checked__I7yz- {
  background: var(--color10);
}
._view_smooth__3aAS6._view_checked__I7yz- span[class*="control-indicator"] {
  border: none;
  box-shadow: none !important;
  background-image: none;
  background-color: var(--color70) !important;
}
._view_smooth__3aAS6._view_checked__I7yz- input:checked ~ span::before,
._view_smooth__3aAS6._view_checked__I7yz-._view_indeterminate__1XXIW span[class*="control-indicator"]::before {
  opacity: 1;
  transition: opacity linear 180ms;
}
._view_smooth__3aAS6:hover input:checked ~ span[class*="control-indicator"] {
  background-color: var(--color70);
  box-shadow: none;
}
._view_smooth__3aAS6 input:not(:checked) ~ span[class*="control-indicator"]::before {
  opacity: 0;
  transition: opacity linear 180ms;
}
/*disabled*/
._view_smooth__3aAS6._view_disabled__1oiX-,
._view_smooth__3aAS6._view_checked__I7yz-._view_disabled__1oiX- {
  color: var(--color40) !important;
  background: var(--color0);
}
._view_smooth__3aAS6._view_checked__I7yz-._view_disabled__1oiX- span[class*="control-indicator"] {
  background-color: var(--color40) !important;
}
._view_smooth__3aAS6._view_disabled__1oiX- span[class*="control-indicator"] {
  border-color: var(--color40) !important;
}
._view_smooth__3aAS6._view_disabled__1oiX-._view_checked__I7yz- span {
  border: none !important;
  background-color: none !important;
}
._view_smooth__3aAS6._view_disabled__1oiX-:hover input:checked ~ span {
  background-color: none !important;
}
._view_smooth__3aAS6._view_disabled__1oiX-:hover ._view_box_layout__lgRqQ {
  background: transparent;
}
/*helper*/
._view_smooth__3aAS6 ._view_helperText__U5wl1,
._view_smooth__3aAS6._view_disabled__1oiX- ._view_helperText__U5wl1 {
  color: var(--color60);
}
/*--------------------------------*/
/*OUTLINED*/
._view_outlined__2bLJK {
  color: var(--color100);
  box-shadow: 0 0 0 1px var(--color20);
}
._view_outlined__2bLJK ._view_box_layout__lgRqQ {
  background: transparent;
  transition: all 100ms cubic-bezier(0.4, 0, 0.2, 1);
}
._view_outlined__2bLJK:hover {
  box-shadow: 0 0 0 1px var(--color40);
  transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1);
}
._view_outlined__2bLJK[class*="control"] span[class*="control-indicator"] {
  background-color: transparent !important;
  background-image: none;
  box-shadow: none !important;
  border: 2px solid var(--color70);
}
._view_outlined__2bLJK._view_checked__I7yz- {
  box-shadow: 0 0 0 2px var(--color40);
}
._view_outlined__2bLJK._view_checked__I7yz- span[class*="control-indicator"] {
  border: none;
  box-shadow: none !important;
  background-image: none;
  background-color: var(--color70) !important;
}
._view_outlined__2bLJK._view_checked__I7yz- input:checked ~ span::before,
._view_outlined__2bLJK._view_checked__I7yz-._view_indeterminate__1XXIW span[class*="control-indicator"]::before {
  opacity: 1;
  transition: opacity linear 180ms;
}
._view_outlined__2bLJK:hover input:checked ~ span[class*="control-indicator"] {
  background-color: var(--color70);
  box-shadow: none;
}
._view_outlined__2bLJK input:not(:checked) ~ span[class*="control-indicator"]::before {
  opacity: 0;
  transition: opacity linear 180ms;
}
/*disabled*/
._view_outlined__2bLJK._view_disabled__1oiX-,
._view_outlined__2bLJK._view_checked__I7yz-._view_disabled__1oiX- {
  color: var(--color40) !important;
  box-shadow: 0 0 0 1px var(--color10);
}
._view_outlined__2bLJK._view_checked__I7yz-._view_disabled__1oiX- span[class*="control-indicator"] {
  background-color: var(--color40) !important;
}
._view_outlined__2bLJK._view_disabled__1oiX- span[class*="control-indicator"] {
  border-color: var(--color40) !important;
}
._view_outlined__2bLJK._view_disabled__1oiX-._view_checked__I7yz- span {
  border: none !important;
  background-color: none !important;
}
._view_outlined__2bLJK._view_disabled__1oiX-:hover input:checked ~ span {
  background-color: none !important;
}
._view_outlined__2bLJK._view_disabled__1oiX-:hover ._view_box_layout__lgRqQ {
  background: transparent;
}
/*helper*/
._view_outlined__2bLJK ._view_helperText__U5wl1,
._view_outlined__2bLJK._view_disabled__1oiX- ._view_helperText__U5wl1 {
  color: var(--color60);
}
/*--------------------------------*/
/*RAISED*/
._view_raised__me6Rb {
  color: var(--color100);
  background: var(--color0);
  box-shadow: var(--shadow2dp);
}
._view_raised__me6Rb ._view_box_layout__lgRqQ {
  background: transparent;
  transition: all 100ms cubic-bezier(0.4, 0, 0.2, 1);
}
._view_raised__me6Rb:hover {
  background: var(--white);
  box-shadow: var(--shadow4dp);
  transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1);
}
._view_raised__me6Rb[class*="control"] span[class*="control-indicator"] {
  background-color: transparent !important;
  background-image: none;
  box-shadow: none !important;
  border: 2px solid var(--color70);
}
._view_raised__me6Rb._view_checked__I7yz- {
  background: var(--color5);
  box-shadow: none;
}
._view_raised__me6Rb._view_checked__I7yz- span[class*="control-indicator"] {
  border: none;
  box-shadow: none !important;
  background-image: none;
  background-color: var(--color70) !important;
}
._view_raised__me6Rb._view_checked__I7yz- input:checked ~ span::before,
._view_raised__me6Rb._view_checked__I7yz-._view_indeterminate__1XXIW span[class*="control-indicator"]::before {
  opacity: 1;
  transition: opacity linear 180ms;
}
._view_raised__me6Rb:hover input:checked ~ span[class*="control-indicator"] {
  background-color: var(--color70);
  box-shadow: none;
}
._view_raised__me6Rb input:not(:checked) ~ span[class*="control-indicator"]::before {
  opacity: 0;
  transition: opacity linear 180ms;
}
/*disabled*/
._view_raised__me6Rb._view_disabled__1oiX-,
._view_raised__me6Rb._view_checked__I7yz-._view_disabled__1oiX- {
  color: var(--color40) !important;
  background: var(--color0);
  box-shadow: none;
}
._view_raised__me6Rb._view_checked__I7yz-._view_disabled__1oiX- span[class*="control-indicator"] {
  background-color: var(--color40) !important;
}
._view_raised__me6Rb._view_disabled__1oiX- span[class*="control-indicator"] {
  border-color: var(--color40) !important;
}
._view_raised__me6Rb._view_disabled__1oiX-._view_checked__I7yz- span {
  border: none !important;
  background-color: none !important;
}
._view_raised__me6Rb._view_disabled__1oiX-:hover input:checked ~ span {
  background-color: none !important;
}
._view_raised__me6Rb._view_disabled__1oiX-:hover ._view_box_layout__lgRqQ {
  background: transparent;
}
/*helper*/
._view_raised__me6Rb ._view_helperText__U5wl1,
._view_raised__me6Rb._view_disabled__1oiX- ._view_helperText__U5wl1 {
  color: var(--color60);
}
/*--------------------------------*/

.style_pageTools__3FuED {
  font-weight: bold;
  font-size: 14px;
  color: var(--grey40);
}

.style_input__34kCx {
  width: 40px;
  text-align: center;
  border-radius: 3px;
  border: 1px solid var(--grey10);
  padding: 3px 0;
}

.style_loadingBackdrop__3F6_Z {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  height: 750px;
  width: 100%;
}

.style_selectbox__Rugmq {
  z-index: 9000;
  position: absolute;
  cursor: default;
  background: none;
  border: 1px dashed grey;
}

.style_deselectButton__FzZ3p {
  position: absolute;
  top: -10px;
  right: -10px;
  background: #fff;
  box-shadow: 0px 2px 4px rgba(27, 78, 163, 0.2);
}

.style_thumbWrapper__3ehX7 {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: column;
          flex-flow: column;
  height: 100%;
  width: 100%;
  /* padding: 10px; */
  overflow: auto;
}

.style_page_wrapper__1b87A {
  width: 100%;
  height: 100%;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  border-radius: 2px;
  /*
  Introduced in Internet Explorer 10.
  See http://ie.microsoft.com/testdrive/HTML5/msUserSelect/
  */
  -ms-user-select: none;
  user-select: none;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  margin: 16px;
}

.style_page_wrapper__1b87A:hover {
  background-color: rgba(0, 0, 0, 0.15);
}

.style_page_wrapper__1b87A.style_active__2hO9u {
  background-color: rgba(0, 0, 0, 0.15);
}

.style_page_wrapper__1b87A.style_active__2hO9u span.style_page_number__EpKzR {
  font-weight: bold;
}
.style_page__3BO3d {
  background-color: #ffffff;
  margin-top: 16px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.5), 0 2px 8px rgba(0, 0, 0, 0.3);
}

.style_page__3BO3d > .style_react-pdf__Page_img__2aEGv {
  /* padding: 16px; */
  width: 100%;
  height: auto;
}

.style_variable_name__3KFPC {
  display: block;
  font-family: var(--inter);
  font-size: 14px;
}

.style_variable_type__1QPV2 {
  display: block;
  font-family: var(--inter);
  font-size: 12px;
  color: var(--grey60);
}

.style_page_number_wrapper__2hI_d {
  width: 100%;
  text-align: center;
  margin: 12px 0px;
}

.style_page_number__EpKzR {
  font-family: var(--inter);
  font-size: var(--font_size_body_small);
  color: var(--grey60);
}

.style_pdf_viewer__3ZqUL {
  overflow: auto;
  height: 100%;
  position: relative;
  background: var(--grey10);
}

.style_pdf_page__BspOy {
  margin: 20px auto;
  border: 10px solid transparent;
  box-sizing: content-box;
}

.style_progress__3ZpO5 {
  margin: 20px auto;
  height: 20px;
  width: 80%;
}

.style_input_wrapper__2FSwT {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin-bottom: 14px;
  background-color: #fff;
}

.style_input_wrapper__2FSwT:not(:last-child) {
  margin-bottom: 14px;
}

.style_header_button_wrapper__3nlIE {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.style_nested_wrapper__316cs {
  border-bottom: 1px solid var(--grey10);
  margin-bottom: 5px;
  margin-left: 10px;
  background-color: #fff;
}

.style_nested_parent_wrapper__1Gnjp {
  margin-bottom: 10px;
}

.style_nested_header_wrapper__2gUoc {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  cursor: pointer;
  margin: 5px 0px;
}

.style_nested_header__3ErjM {
  margin: 0;
  font-family: var(--inter);
  font-size: var(--font_size_caption);
  color: var(--grey60);
  padding: 6px 0px;
}

.style_count_badge__Usyf5 {
  display: block;
  font-family: var(--inter);
  font-size: var(--font_size_body_small);
  color: var(--grey70);
  font-weight: 500;
  text-align: center;
  border-radius: 50%;
  background: var(--grey10);
  line-height: 20px;
  height: 20px;
  width: 20px;
  margin-right: 5px;
}

.style_border_bottom__3vyhl {
  background-color: #fff;
}

.style_border_bottom__3vyhl:not(:last-child) {
  border-bottom: 1px solid var(--grey10);
  margin-bottom: 10px;
}

.style_userBoxWrapperBounding__1HfsO {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row-reverse;
          flex-flow: row-reverse;
  position: absolute;
  top: -15px;
  right: -2px;
}

.style_userBox__20mBl {
  display: block;
  height: 15px;
  padding: 0px 5px;
  font-family: var(--inter);
  font-size: 10px;
  color: #fff;
  white-space: nowrap;
}

.style_popover__2Ch7t {
  margin-top: 20px;
  box-shadow: none;
}

.style_infoContainer__1KMpE {
  border-radius: 0.25rem;
  margin-top: 10px;
  background: #4a4b57;
  color: #ffffff;
  padding: 1rem;
  width: 314px;
  box-shadow: 0px 1px 2px rgba(58, 58, 68, 0.24),
    0px 2px 4px rgba(90, 91, 106, 0.24);
}

.style_dotInfo__1bbdx {
  display: block;
  margin-top: 2px;
  width: 12px;
  border-top: 6px solid var(--blue40);
  border-bottom: 6px solid var(--yellow40);
  border-radius: 50%;
}

.style_dotError__1M37O {
  display: block;
  margin-top: 2px;
  width: 12px;
  height: 12px;
  background: var(--red40);
  border-radius: 50%;
}

.style_searchResultContainer__96GT3 {
  margin-top: 16px;
  height: calc(100vh - 242px);
}

.style_emptySearchResult__biTOe {
  width: 100%;
  height: 36px;
  background: var(--grey0);
  border-radius: 4px;
  font-size: 14px;
  font-family: var(--inter);
  color: var(--grey100);
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.style_resultList__1Aat6 {
  width: 100%;
  height: 25px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  cursor: pointer;
  margin-bottom: 24px;
  padding-left: 5px;
  padding-right: 5px;
}

.style_resultList__1Aat6 span {
  display: inline-block;
  font-size: 12px;
  font-family: var(--inter);
  color: var(--grey100);
  -webkit-flex-grow: 0;
          flex-grow: 0;
  white-space: nowrap;
}

.style_matchText__2l2ey {
  width: 90%;
  overflow: hidden;
}

.style_item_options__DNDdt {
  max-height: 300px;
  width: 221px;
  overflow-y: auto;
}

.style_item_options__DNDdt ul {
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: 14px;
}

.style_item_options__DNDdt ul li {
  width: 100%;
  display: block;
  padding: 10px 15px;
  cursor: pointer;
  transition: all 500ms ease;
  color: var(--grey60);
}

.style_item_options__DNDdt ul li:hover {
  background-color: var(--grey0);
}

.style_accordion_title_container__32BT7 {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
}
/* POPOVER TYPES*/
._type_menu__PKffH,
._type_menu__PKffH > div[class*="popover-content"] {
  border-radius: 0.75rem;
  box-shadow: none;
}

.Style_variable_text_input__3cHEV {
  box-sizing: border-box;
  height: 28px;
  width: 100%;
  border: 1px solid var(--grey20);
  padding: 2px 8px;
}

.Style_input_focused__26dpG {
  border: 2px solid var(--teal40);
}

.Style_date_rejected__2c89Z {
  background: var(--red20);
}

.Style_select_input__3S2oM {
  box-sizing: border-box;
  border: 1px solid var(--grey20);
  background: #fff;
  width: 100%;
  padding: 2px 4px;
  color: var(--grey60);
  font-family: var(--inter);
  font-size: var(--font_size_caption);
  height: 28px;
}

.Style_input_icon__1BG1l {
  color: var(--grey50);
  margin-right: 7px;
}

.Style_input_suggestion_wrapper__1rhQB {
  width: 100%;
}

.Style_input_suggestion__h8FUB {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  width: 100%;
}

.Style_item_options__33Rqs {
  max-height: 300px;
  width: 221px;
  overflow-y: auto;
}

.Style_item_options__33Rqs ul {
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: 14px;
}

.Style_item_options__33Rqs ul li {
  width: 100%;
  display: block;
  padding: 10px 15px;
  cursor: pointer;
  transition: all 500ms ease;
  color: var(--grey60);
}

.Style_item_options__33Rqs ul li:hover {
  background-color: var(--grey0);
}

.Style_searchInput__1Sd3b {
  border-radius: 4px;
  border: 1px solid var(--grey30);
  height: 37px;
  width: 100%;
  padding-left: 32px;
  padding-right: 15px;
  font-size: 12px;
}

.Style_resetSearchButton__1VhbI {
  position: absolute;
  right: 10px;
  color: var(--grey60);
  cursor: pointer;
}

.Style_page_range_target__WEX52 {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  border: 1px solid var(--grey30);
  color: var(--grey100);
  font-weight: normal;
  font-size: 14px;
  padding: 4px;
  border-radius: 2px;
  cursor: pointer;
  min-width: 115px;
}

.Style_page_range_popover__3sqD5 {
  border: none;
  box-shadow: none;
}

.Style_page_range_popover__3sqD5 > div {
  background-color: transparent !important;
}

.Style_page_range_popover_content__1JML- {
  border: none;
  background-color: #fff;
  margin-top: 10px;
  padding: 24px;
  box-shadow: 0 5px 10px var(--grey20);
  border-radius: 10px;
}

.Style_page_range_container__34Upf {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  margin-top: 16px;
}

.Style_page_range_input__3laI2 {
  width: 72px;
  padding: 8px;
  text-align: center;
  border: 1px solid var(--grey20);
  border-radius: 4px;
  font-size: 14px;
}

.Style_page_range_separator__1f2ZM {
  display: block;
  width: 24px;
  height: 1px;
  margin: 0px 16px;
  border-top: 1px solid var(--grey20);
}

.Style_report_container__DbZKL {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 100%;
  max-height: 55vh;
  overflow-y: auto;
}

.Style_report_item__1xSRx {
  padding: 16px 0;
  overflow: hidden;
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
}

.Style_report_item__1xSRx:first-child {
  padding-top: 0;
}

.Style_report_item__1xSRx:last-child  {
  padding-bottom: 0
}

.Style_report_item__1xSRx:not(:last-child) {
  border-bottom: 1px solid var(--grey10)
}

.Style_report_item__1xSRx span {
  display: block;
  font-size: 12px;
  color: var(--grey50);
  margin-bottom: 4px;
}

.Style_report_item__1xSRx p {
  color: var(--grey100);
  font-size: 12px;
  margin-bottom: 0;
}

.Style_report_header_container__2Udte {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  width: 100%;
}

.Style_report_header_container__2Udte span {
  display: block;
}

.Style_report_header_container__2Udte button {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  cursor: pointer;
  border: none;
  border-radius: 50%;
  padding: 2px;
}

.Style_inputNotes__291pE {
  border: 1px solid var(--grey30);
  padding: 10px;
  resize: none;
  font-family: var(--inter);
  font-size: var(--font_size_body_small);
  height: 100%;
  width: 100%;
}
/* DIALOG TYPES*/
/*--DEFAULT--*/
._type_default__15hw_ {
  width: 98%;
  max-width: 30.5rem;
  padding: 2.5rem;
  border-radius: 1.5rem;
}

._type_header__10OfE {
  display: -webkit-flex;
  display: flex;
  margin-bottom: 1rem;
}
._type_header__10OfE > span[class*="icon"] {
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 1rem;
}
._type_footer__qEVTe {
  margin-top: 3rem;
}
._type_left-button__YkV-m {
  float: left;
}
._type_right-button__3F229 {
  float: right;
}

._type_dense__rE0LY {
  width: 98%;
  max-width: 20.5rem;
  padding: 1rem;
  border-radius: 1rem;
}
._type_dense__rE0LY._type_header__10OfE {
  margin-bottom: 0.75rem;
}
._type_dense__rE0LY._type_header__10OfE > span[class*="icon"] {
  margin-right: 0.75rem;
}
._type_dense__rE0LY._type_footer__qEVTe {
  margin-top: 1.75rem;
}

._type_close-button__1aY8Z {
  margin-right: 0;
  margin-left: auto;
}


/*DIALOG STYLE*/

/*SMOOTH*/
._view_smooth__2cYPP {
  background: var(--color5);
  box-shadow: none;
}
/*RAISED*/
._view_raised___aiO2 {
  background: var(--white);
  box-shadow: var(--shadow24dp);
}
/*FILLED*/
._view_filled__d4XN8 {
  background: var(--color50);
  box-shadow: none;
}
._view_filled__d4XN8 > div[class*="header"] > div,
._view_filled__d4XN8 > div[class*="body"] {
  color: var(--color0);
}
/*ICON*/
._view_filled__d4XN8 > div[class*="header"] > span[class*="icon"] > svg,
._view_raised___aiO2 > div[class*="header"] > span[class*="icon"] > svg,
._view_smooth__2cYPP > div[class*="header"] > span[class*="icon"] > svg {
  fill: var(--color30);
}
/*CLOSE_BUTTON*/
._view_filled__d4XN8 > div[class*="header"] div[class*="close-button"] svg,
._view_raised___aiO2 > div[class*="header"] div[class*="close-button"] svg,
._view_smooth__2cYPP > div[class*="header"] div[class*="close-button"] svg {
  fill: var(--color30);
  transition: all 150ms ease-in-out;
}

._view_raised___aiO2 > div[class*="header"] div[class*="close-button"]:hover svg,
._view_smooth__2cYPP > div[class*="header"] div[class*="close-button"]:hover svg {
  fill: var(--color60);
  transition: all 150ms ease-in-out;
}
._view_filled__d4XN8 > div[class*="header"] div[class*="close-button"]:hover svg {
  fill: var(--color5);
  transition: all 150ms ease-in-out;
}
._view_filled__d4XN8 > div[class*="header"] div[class*="close-button"]:hover,
._view_raised___aiO2 > div[class*="header"] div[class*="close-button"]:hover,
._view_smooth__2cYPP > div[class*="header"] div[class*="close-button"]:hover {
  cursor: pointer;
  transition: all 150ms ease-in-out;
}

/*BACKDROP STYLE*/
._view_backdrop-0__CR5OY {
  background-color: rgba(25, 25, 29, 0);
}
._view_backdrop-10__123AS {
  background-color: rgba(25, 25, 29, 0.1);
}
._view_backdrop-20__20Pdc {
  background-color: rgba(25, 25, 29, 0.2);
}
._view_backdrop-30__2OIbN {
  background-color: rgba(25, 25, 29, 0.3);
}
._view_backdrop-40__2pPPd {
  background-color: rgba(25, 25, 29, 0.4);
}
._view_backdrop-50__LK9KS {
  background-color: rgba(25, 25, 29, 0.5);
}
._view_backdrop-60__3dMeq {
  background-color: rgba(25, 25, 29, 0.6);
}
._view_backdrop-70__3_Iae {
  background-color: rgba(25, 25, 29, 0.7);
}
._view_backdrop-80__3uxB3 {
  background-color: rgba(25, 25, 29, 0.8);
}
._view_backdrop-90__2Scga {
  background-color: rgba(25, 25, 29, 0.9);
}
._view_backdrop-100__HNQ9q {
  background-color: rgba(25, 25, 29, 1);
}
/* END STYLE */

.react-datepicker-wrapper {
  display: inline-block;
  padding: 0;
  margin: 0;
  width: 100%;
}

.SearchInput_searchInput__2APvB {
  border-radius: 4px;
  border: 1px solid var(--grey30);
  height: 37px;
  width: 100%;
  padding-left: 32px;
  padding-right: 15px;
}

.Style_base__2FKvs {
	padding: 4px 6px;
	border-radius: 99px;
	font-size: 12px;
	white-space: nowrap;
}

.Style_default__3YS6z {
	background-color: var(--grey5);
	color: var(--grey70);
}

.Style_success__2GPxe {
	background-color: var(--green5);
	color: var(--green70);
}

.Style_warning__3TxgV {
	background-color: var(--orange5);
	color: var(--orange70);
}

.Style_primary__1aaXg {
	background-color: var(--blue5);
	color: var(--blue70);
}

.Style_danger__12OtU {
	background-color: var(--red5);
	color: var(--red70);
}

.Style_default-alt__39mPo {
	color: var(--grey5);
	background-color: var(--grey70);
}

.Style_success-alt__d1HDG {
	color: var(--green5);
	background-color: var(--green70);
}

.Style_warning-alt__1p2T5 {
	color: var(--orange5);
	background-color: var(--orange70);
}

.Style_primary-alt__31OL_ {
	color: var(--blue5);
	background-color: var(--blue70);
}

.Style_danger-alt__mwfq- {
	color: var(--red5);
	background-color: var(--red70);
}
.MetricCard_metricCard__3OP8i {
    display: -webkit-flex;
    display: flex;
    background: #ffffff;
    border-radius: 8px;
    min-height: 92px;
    margin: 0px 8px;
    min-width: 175px;
    /* max-width: 400px; */
    -webkit-flex-basis: 0;
            flex-basis: 0;
    -webkit-flex-grow: 1;
            flex-grow: 1;
    box-shadow: var(--shadow4dp);
}

.MetricCard_metricCardContent__1pSKy {
    margin: 16px;
    position: relative;
    width: 100%;
    /* height: 100%; */
}

.MetricCard_metricCardContent__1pSKy p {
    margin-bottom: 0px;
}

.MetricCard_title__3iVue {
    font-size: 13px;
    font-weight: 500;
    color: var(--grey50);
}

.MetricCard_number__1t109 {
    font-size: 24px;
    font-weight: 600;
    color: var(--grey70);
}

.MetricCard_description__2akTC {
    font-size: 13px;
    font-weight: 500;
}

.MetricCard_description__2akTC div {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    position: relative;
    font-size: 12px;
}

.MetricCard_description__2akTC p {
    white-space: nowrap;
}

.MetricCard_logoContainer__3tW0F {
    position: absolute;
    top: 0;
    right: 0;
    width: 42px;
    height: 42px;
    border-radius: 50%;
}

.MetricCard_logo__3MGpU {
    margin-right: 4px;
}
.MetricContainer_container__KoZhO {
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
	-webkit-justify-content: space-between;
	        justify-content: space-between;
}

.MetricContainer_metricContainer__1pCot {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row nowrap;
            flex-flow: row nowrap;
    -webkit-justify-content: stretch;
            justify-content: stretch;
	overflow: hidden;
}

.MetricContainer_arrowButton__xhTwF {
	color: #fff;
}
.table_tableHeaderContainer__K_sGA {
    background: #fff;
    padding: 12px 7px;
    border-radius: 8px;
    box-shadow: var(--shadow4dp);
}

.table_label__2oWhO {
    font-family: var(--inter);
    font-weight: bold;
    margin-bottom: 8px;
}

.table_roundBox__30GLc {
    border-radius: .8em;
}

.table_menu__1c7jD {
    position: absolute;
    z-index: 10;
    right: 21px;
    top: 0;
}

.table_tableContainer__m2T39 {
    background: #fff;
    border-radius: 8px;
    box-shadow: var(--shadow4dp);
    min-height: 80%;
}

.table_tableToolbar__1DWQq {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    padding: 15px 22px;
}

.table_tableTitle__3rwRP {
    font-family: var(--inter);
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 158%;
    letter-spacing: 0.004em;
    margin: 0;
}

.table_searchInput__39RLs {
    width: 191px;
    margin-right: 15px;
    height: 42px;
}

.table_mainButton__3mY8u {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
}

.Dashboard_root__1EJPA {
    width: 100%;
}

.Dashboard_dashboardCards__1MHFa {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row nowrap;
            flex-flow: row nowrap;
    -webkit-justify-content: stretch;
            justify-content: stretch;
    margin: 16px -8px
}


.style_avatarWrapper__4vdre {
  margin-right: 5px;
  position: relative;
}

.style_avatarItem___F30Y:hover .style_avatarDelete__1oMVC {
  display: -webkit-flex;
  display: flex;
}

.style_avatarIcon__21TdG {
  margin-right: 5px;
}

.style_avatarDelete__1oMVC {
  display: none;
  position: absolute;
  top: -5px;
  right: 3px;
  z-index: 10;
  background: #ffffff;
  color: var(--blue60);
  border-radius: 50%;
  width: 15px;
  height: 15px;
  box-shadow: 1px 2px 4px rgba(0,0,0,.3);
  font-size: 1rem;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.style_toggleAddMember__3RjWv {
  color: var(--blue60);
  text-decoration: underline;
  cursor: pointer;
}

.style_editMode__2onQV .style_avatarDelete__1oMVC {
  display: -webkit-flex;
  display: flex;
}

.style_groupIconWrapper__GeR9h {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  background: #F1F3F4;    
  margin-right: 10px;
}

.style_flex__mGvpo {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.style_flexEnd__TzKw1 {
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
}

.style_lastCell__2uKbD {
  width: 50px;
}

.style_pointer__2OGyG:hover {
  cursor: pointer;
}

.style_relative__39M_q {
  position: relative;
}

.style_hover__27CIQ:hover {
  cursor: pointer;
}
/**INPUT*/
._type_def__ZPeEj,
._type_dense__B3GbZ/*,
.fixed*/ {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
._type_full__2zC_b {
  width: 100%;
}
._type_def__ZPeEj[class*="input-group"] input[class*="input"] {
  border-radius: 0.25rem;
  padding-left: 0.75rem;
  padding-right: 0.625rem !important;
  margin-top: 0.125rem;
  margin-bottom: 0.125rem;
}
._type_def__ZPeEj[class*="input-group"] input[class*="input"] {
  height: 2.5rem;
  font-size: var(--font_size_body);
  letter-spacing: var(--letter_spacing_body);
  font-weight: var(--font_weight_body);
}

._type_def__ZPeEj label {
  font-size: var(--font_size_body_small);
  letter-spacing: var(--letter_spacing_body_small);
  font-weight: var(--font_weight_body_small);
  transition: all 200ms ease-in-out;
  z-index: 1;
  pointer-events: none;
}
/*Dense*/
._type_dense__B3GbZ[class*="input-group"] input[class*="input"] {
  border-radius: 0.25rem;
  padding-left: 0.5rem;
  padding-right: 0.375rem !important;
  margin-top: 0.0625rem;
  margin-bottom: 0.0625rem;
}
._type_dense__B3GbZ[class*="input-group"] input[class*="input"] {
  height: 1.75rem;
  font-size: var(--font_size_body_small);
  letter-spacing: var(--letter_spacing_body_small);
  font-weight: var(--font_weight_body);
}
._type_dense__B3GbZ label {
  font-size: var(--font_size_caption);
  letter-spacing: var(--letter_spacing_caption);
  font-weight: var(--font_weight_caption);
  transition: all 200ms ease-in-out;
  z-index: 1;
  pointer-events: none;
}

/*CLEAR BUTTON*/
._type_def__ZPeEj._type_clearButton__2SopT[class*="input-group"] span[class*="input-action"] > button {
  height: 2.5rem;
  padding: 0;
  margin: 0;
  margin-right: 0.5rem;
  margin-top: 0.125rem;
}
._type_dense__B3GbZ._type_clearButton__2SopT[class*="input-group"] span[class*="input-action"] > button {
  height: 1.75rem;
  padding: 0;
  margin: 0;
  margin-right: 0.125rem;
  margin-top: 0.0625rem;
}
._type_dense__B3GbZ._type_clearButton__2SopT[class*="input-group"]
  span[class*="input-action"]
  > button
  svg {
  width: 1rem;
  height: 1rem;
}
._type_clearButton__2SopT[class*="input-group"] span[class*="input-action"] {
  top: auto;
  top: initial;
}
/*RIGHT ICON*/
._type_def__ZPeEj._type_right-icon__2pcp4[class*="input-group"] span[class*="input-action"] {
  padding: 0;
  margin: 0;
  margin-right: 0.625rem;
  margin-top: 0.75rem;
}
._type_dense__B3GbZ._type_right-icon__2pcp4[class*="input-group"] span[class*="input-action"] {
  padding: 0;
  margin: 0;
  margin-right: 0.375rem;
  bottom: 0.4375rem;
}
._type_right-icon__2pcp4[class*="input-group"] span[class*="input-action"] {
  top: auto;
  top: initial;
}

/*-----*/
/*HELPER TEXT*/
._type_def__ZPeEj ._type_error-helper-text__298Tk {
  position: absolute;
  bottom: -1rem;
  z-index: 1;
  /*padding-left: 0.75rem;*/
  font-size: var(--font_size_caption);
  letter-spacing: var(--letter_spacing_caption);
  font-weight: var(--font_weight_caption);
  -webkit-animation: _type_shake-horizontal__v88Xb 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
          animation: _type_shake-horizontal__v88Xb 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
}
._type_dense__B3GbZ ._type_error-helper-text__298Tk {
  position: absolute;
  bottom: -0.875rem;
  z-index: 1;
  /*padding-left: 0.75rem;*/
  font-size: var(--font_size_caption_small);
  letter-spacing: var(--letter_spacing_caption_small);
  font-weight: var(--font_weight_caption_small);
  -webkit-animation: _type_shake-horizontal__v88Xb 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
          animation: _type_shake-horizontal__v88Xb 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
}

/*ANIMATION*/
@-webkit-keyframes _type_shake-horizontal__v88Xb {
  0%,
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  10%,
  30%,
  50%,
  70% {
    -webkit-transform: translateX(-10px);
    transform: translateX(-10px);
  }
  20%,
  40%,
  60% {
    -webkit-transform: translateX(10px);
    transform: translateX(10px);
  }
  80% {
    -webkit-transform: translateX(8px);
    transform: translateX(8px);
  }
  90% {
    -webkit-transform: translateX(-8px);
    transform: translateX(-8px);
  }
}
@keyframes _type_shake-horizontal__v88Xb {
  0%,
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  10%,
  30%,
  50%,
  70% {
    -webkit-transform: translateX(-10px);
    transform: translateX(-10px);
  }
  20%,
  40%,
  60% {
    -webkit-transform: translateX(10px);
    transform: translateX(10px);
  }
  80% {
    -webkit-transform: translateX(8px);
    transform: translateX(8px);
  }
  90% {
    -webkit-transform: translateX(-8px);
    transform: translateX(-8px);
  }
}

/*INPUT*/

/*SMOOTH*/
._view_smooth__tkBw7[class*="input-group"] input[class*="input"],
._view_smooth__tkBw7._view_active__2tTVa._view_isFilled__15ONW[class*="input-group"] input[class*="input"] {
  box-shadow: none;
  background: var(--color5);
  color: var(--color100);
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
}
._view_smooth__tkBw7[class*="input-group"]._view_active__2tTVa input[class*="input"],
._view_smooth__tkBw7[class*="input-group"] input[class*="input"]:hover {
  color: var(--color100);
  background: var(--color10);
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
}
._view_smooth__tkBw7[class*="input-group"] input[class*="input"]::-webkit-input-placeholder {
  color: var(--color40);
  opacity: 1;
  visibility: visible;
  display: inline;
  display: initial;
}
._view_smooth__tkBw7[class*="input-group"] input[class*="input"]:-ms-input-placeholder {
  color: var(--color40);
  opacity: 1;
  visibility: visible;
  display: inline;
  display: initial;
}
._view_smooth__tkBw7[class*="input-group"] input[class*="input"]::placeholder {
  color: var(--color40);
  opacity: 1;
  visibility: visible;
  display: inline;
  display: initial;
}
._view_smooth__tkBw7[class*="input-group"] label {
  opacity: 0;
  color: var(--color50);
}
._view_smooth__tkBw7[class*="input-group"]._view_active__2tTVa._view_isFilled__15ONW label,
._view_smooth__tkBw7[class*="input-group"]._view_active__2tTVa label {
  color: var(--color50);
  opacity: 1;
}
._view_smooth__tkBw7[class*="input-group"] input[class*="input"]:focus {
  box-shadow: none;
}
/**END SMOOTH*/

/*OUTLINED*/
._view_outlined__1CFDq[class*="input-group"] input[class*="input"],
._view_outlined__1CFDq._view_active__2tTVa[class*="input-group"] input[class*="input"],
._view_outlined__1CFDq._view_active__2tTVa._view_isFilled__15ONW[class*="input-group"] input[class*="input"] {
  box-shadow: 0 0 0 1px var(--color30);
  background: var(--white);
  color: var(--color100);
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
}
._view_outlined__1CFDq[class*="input-group"] input[class*="input"]:hover,
._view_outlined__1CFDq[class*="input-group"]._view_isFilled__15ONW input[class*="input"]:hover {
  color: var(--color100);
  box-shadow: 0 0 0 1px var(--color80);
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
}
._view_outlined__1CFDq[class*="input-group"]._view_active__2tTVa input[class*="input"] {
  box-shadow: 0 0 0 2px var(--color80);
}
._view_outlined__1CFDq[class*="input-group"] input[class*="input"]::-webkit-input-placeholder {
  color: var(--color40);
  opacity: 1;
  visibility: visible;
  display: inline;
  display: initial;
}
._view_outlined__1CFDq[class*="input-group"] input[class*="input"]:-ms-input-placeholder {
  color: var(--color40);
  opacity: 1;
  visibility: visible;
  display: inline;
  display: initial;
}
._view_outlined__1CFDq[class*="input-group"] input[class*="input"]::placeholder {
  color: var(--color40);
  opacity: 1;
  visibility: visible;
  display: inline;
  display: initial;
}
._view_outlined__1CFDq[class*="input-group"] label {
  opacity: 0;
  color: var(--color50);
}
._view_outlined__1CFDq[class*="input-group"]._view_active__2tTVa._view_isFilled__15ONW label,
._view_outlined__1CFDq[class*="input-group"]._view_active__2tTVa label {
  color: var(--color50);
  opacity: 1;
}
/**END OUTLINED*/

/*RAISED*/
._view_raised__s8_A8[class*="input-group"] input[class*="input"],
._view_raised__s8_A8._view_active__2tTVa[class*="input-group"] input[class*="input"],
._view_raised__s8_A8._view_active__2tTVa._view_success__3yDA9[class*="input-group"] input[class*="input"],
._view_raised__s8_A8._view_active__2tTVa._view_error-helper-text__2MCIB[class*="input-group"] input[class*="input"],
._view_raised__s8_A8._view_active__2tTVa._view_isFilled__15ONW[class*="input-group"] input[class*="input"] {
  box-shadow: var(--shadow2dp);
  background: var(--white);
  color: var(--color100);
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
}
._view_raised__s8_A8[class*="input-group"] input[class*="input"]:hover,

._view_raised__s8_A8[class*="input-group"]._view_isFilled__15ONW input[class*="input"]:hover {
  color: var(--color100);
  box-shadow: var(--shadow4dp);
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
}
._view_raised__s8_A8[class*="input-group"]._view_active__2tTVa input[class*="input"] {
  box-shadow: var(--shadow8dp);
}
._view_raised__s8_A8[class*="input-group"] input[class*="input"]::-webkit-input-placeholder {
  color: var(--color40);
  opacity: 1;
  visibility: visible;
  display: inline;
  display: initial;
}
._view_raised__s8_A8[class*="input-group"] input[class*="input"]:-ms-input-placeholder {
  color: var(--color40);
  opacity: 1;
  visibility: visible;
  display: inline;
  display: initial;
}
._view_raised__s8_A8[class*="input-group"] input[class*="input"]::placeholder {
  color: var(--color40);
  opacity: 1;
  visibility: visible;
  display: inline;
  display: initial;
}
._view_raised__s8_A8[class*="input-group"] input[class*="input"]:hover::-webkit-input-placeholder {
  color: var(--color60);
}
._view_raised__s8_A8[class*="input-group"] input[class*="input"]:hover:-ms-input-placeholder {
  color: var(--color60);
}
._view_raised__s8_A8[class*="input-group"] input[class*="input"]:hover::placeholder {
  color: var(--color60);
}
._view_raised__s8_A8[class*="input-group"] label {
  color: var(--color50);
  opacity: 0;
}
._view_raised__s8_A8[class*="input-group"]._view_active__2tTVa._view_isFilled__15ONW label,
._view_raised__s8_A8[class*="input-group"]._view_active__2tTVa label {
  color: var(--color50);
  opacity: 1;
}
/**END RAISED*/

/*FILLED*/
._view_filled__1e8U2[class*="input-group"] input[class*="input"],
._view_filled__1e8U2._view_active__2tTVa[class*="input-group"] input[class*="input"],
._view_filled__1e8U2._view_active__2tTVa._view_isFilled__15ONW[class*="input-group"] input[class*="input"] {
  box-shadow: none;
  background: var(--color30);
  color: var(--color0);
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
}
._view_filled__1e8U2[class*="input-group"] input[class*="input"]:hover,
._view_filled__1e8U2[class*="input-group"]._view_isFilled__15ONW input[class*="input"]:hover {
  color: var(--color0);
  box-shadow: none;
  background: var(--color40);
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
}
._view_filled__1e8U2[class*="input-group"]._view_active__2tTVa input[class*="input"] {
  box-shadow: none;
  background: var(--color70);
}
._view_filled__1e8U2[class*="input-group"] input[class*="input"]::-webkit-input-placeholder {
  color: var(--color0);
  opacity: 1;
  visibility: visible;
  display: inline;
  display: initial;
}
._view_filled__1e8U2[class*="input-group"] input[class*="input"]:-ms-input-placeholder {
  color: var(--color0);
  opacity: 1;
  visibility: visible;
  display: inline;
  display: initial;
}
._view_filled__1e8U2[class*="input-group"] input[class*="input"]::placeholder {
  color: var(--color0);
  opacity: 1;
  visibility: visible;
  display: inline;
  display: initial;
}
._view_filled__1e8U2[class*="input-group"] label {
  opacity: 0;
}
._view_filled__1e8U2[class*="input-group"]._view_active__2tTVa._view_isFilled__15ONW label,
._view_filled__1e8U2[class*="input-group"]._view_active__2tTVa label {
  color: var(--color50);
  opacity: 1;
}
/**END FILLED*/

/*HELPER TEXT*/
._view_error-helper-text__2MCIB {
  color: var(--red60);
}
/*end*/

/*RIGHT ICON*/
._view_error-helper-text__2MCIB span[class*="input-action"] {
  color: var(--color60);
}
._view_filled__1e8U2._view_error-helper-text__2MCIB span[class*="input-action"] {
  color: var(--color0);
}
._view_success__3yDA9 span[class*="input-action"] {
  color: var(--color60);
}
._view_filled__1e8U2._view_success__3yDA9 span[class*="input-action"] {
  color: var(--color0);
}
/*-----*/
/*CLEAR BUTTON*/
._view_clearButton__10UjI[class*="input-group"]
  > input
  + span[class*="input-action"]
  > button {
  visibility: hidden;
  opacity: 0;
  cursor: pointer;
}
._view_clearButton__10UjI[class*="input-group"]._view_isFilled__15ONW
  span[class*="input-action"]
  > button,
._view_clearButton__10UjI[class*="input-group"]._view_active__2tTVa span[class*="input-action"] > button {
  visibility: hidden;
  opacity: 0;
  transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1);
}
._view_clearButton__10UjI[class*="input-group"]._view_active__2tTVa
  span[class*="input-action"]
  > button[class*="button"],
._view_clearButton__10UjI[class*="input-group"]._view_active__2tTVa
  span[class*="input-action"]
  > button[class*="button"]
  > span[class*="icon"] {
  color: var(--color40) !important;
  opacity: 1;
  visibility: visible;
  transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1);
}
._view_filled__1e8U2._view_clearButton__10UjI[class*="input-group"]:hover
  span[class*="input-action"]
  > button[class*="button"]
  > span[class*="icon"] {
  color: var(--color5) !important;
  transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1);
}
._view_clearButton__10UjI[class*="input-group"]._view_active__2tTVa._view_isFilled__15ONW
  span[class*="input-action"]
  > button {
  visibility: hidden;
  opacity: 0;
  transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1);
}
._view_clearButton__10UjI[class*="input-group"]._view_active__2tTVa._view_isFilled__15ONW:hover
  span[class*="input-action"]
  > button {
  visibility: visible;
  opacity: 1;
  transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1);
}

/*END CLEAR BUTTON*/

/* DIALOG TYPES*/
/*--DEFAULT--*/
._type_default__261Vf {
  width: 98%;
  max-width: 30.5rem;
  padding: 2.5rem;
  border-radius: 1.5rem;
}

._type_header__c4Gb3 {
  display: -webkit-flex;
  display: flex;
  margin-bottom: 1rem;
}
._type_header__c4Gb3 > span[class*="icon"] {
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 1rem;
}
._type_footer__37B_2 {
  margin-top: 3rem;
}
._type_left-button__1v1jj {
  float: left;
}
._type_right-button__3w1T9 {
  float: right;
}

._type_dense__2v968 {
  width: 98%;
  max-width: 20.5rem;
  padding: 1rem;
  border-radius: 1rem;
}
._type_dense__2v968._type_header__c4Gb3 {
  margin-bottom: 0.75rem;
}
._type_dense__2v968._type_header__c4Gb3 > span[class*="icon"] {
  margin-right: 0.75rem;
}
._type_dense__2v968._type_footer__37B_2 {
  margin-top: 1.75rem;
}

._type_close-button__DYimu {
  margin-right: 0;
  margin-left: auto;
}

/*DIALOG STYLE*/

/*SMOOTH*/
._view_smooth__2xl1w {
  background: var(--color5);
  box-shadow: none;
}
/*RAISED*/
._view_raised__2NtEx {
  background: var(--white);
  box-shadow: var(--shadow24dp);
}
/*FILLED*/
._view_filled__2Enjd {
  background: var(--color50);
  box-shadow: none;
}
._view_filled__2Enjd > div[class*="header"] > div,
._view_filled__2Enjd > div[class*="body"] {
  color: var(--color0);
}
/*ICON*/
._view_filled__2Enjd > div[class*="header"] > span[class*="icon"] > svg,
._view_raised__2NtEx > div[class*="header"] > span[class*="icon"] > svg,
._view_smooth__2xl1w > div[class*="header"] > span[class*="icon"] > svg {
  fill: var(--color30);
}
/*CLOSE_BUTTON*/
._view_filled__2Enjd > div[class*="header"] div[class*="close-button"] svg,
._view_raised__2NtEx > div[class*="header"] div[class*="close-button"] svg,
._view_smooth__2xl1w > div[class*="header"] div[class*="close-button"] svg {
  fill: var(--color30);
  transition: all 150ms ease-in-out;
}

._view_raised__2NtEx > div[class*="header"] div[class*="close-button"]:hover svg,
._view_smooth__2xl1w > div[class*="header"] div[class*="close-button"]:hover svg {
  fill: var(--color60);
  transition: all 150ms ease-in-out;
}
._view_filled__2Enjd > div[class*="header"] div[class*="close-button"]:hover svg {
  fill: var(--color5);
  transition: all 150ms ease-in-out;
}
._view_filled__2Enjd > div[class*="header"] div[class*="close-button"]:hover,
._view_raised__2NtEx > div[class*="header"] div[class*="close-button"]:hover,
._view_smooth__2xl1w > div[class*="header"] div[class*="close-button"]:hover {
  cursor: pointer;
  transition: all 150ms ease-in-out;
}

/*BACKDROP STYLE*/
._view_backdrop-0__BBzeI {
  background-color: rgba(25, 25, 29, 0);
}
._view_backdrop-10__rElnN {
  background-color: rgba(25, 25, 29, 0.1);
}
._view_backdrop-20__12tsO {
  background-color: rgba(25, 25, 29, 0.2);
}
._view_backdrop-30__3q5Wu {
  background-color: rgba(25, 25, 29, 0.3);
}
._view_backdrop-40__1gx_A {
  background-color: rgba(25, 25, 29, 0.4);
}
._view_backdrop-50__31bnw {
  background-color: rgba(25, 25, 29, 0.5);
}
._view_backdrop-60__1pJ3g {
  background-color: rgba(25, 25, 29, 0.6);
}
._view_backdrop-70__3fZ-v {
  background-color: rgba(25, 25, 29, 0.7);
}
._view_backdrop-80__25V08 {
  background-color: rgba(25, 25, 29, 0.8);
}
._view_backdrop-90__19Dmu {
  background-color: rgba(25, 25, 29, 0.9);
}
._view_backdrop-100__2U3Bq {
  background-color: rgba(25, 25, 29, 1);
}
/* END STYLE */

.style_mainButton__1ZANN {
    font-weight: normal;
    border-radius: 3px;
    padding: 22px;
    background: var(--blue70)!important;
    box-shadow: none!important;
}
.style_loadingBackdrop__1SA6a {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    padding: 15% 0;
}

.style_widgetItem__1tG6K {
    margin-right: 15px;
}

.style_widgetItem__1tG6K:last-child{ 
    margin-right: 0px;
}

.style_menu__Qc-5b {
    position: absolute;
    z-index: 10;
    right: 45px;
    top: 0;
  }
  

.style_searchBoxInput__2V7DG {
    padding: 13px 15px!important;
}
.style_searchBoxInput__2V7DG::-webkit-input-placeholder {
    color: var(--blue60)
}
.style_searchBoxInput__2V7DG:-ms-input-placeholder {
    color: var(--blue60)
}
.style_searchBoxInput__2V7DG::placeholder {
    color: var(--blue60)
}

.style_searchBoxOutline__vUcu_ {
    border-color: var(--blue10)!important;
    
}
.style_searchBoxIcon__2ElZ8 {
    color: var(--blue40);
    font-size: 2.3rem;
}


.style_cardTable__16s6f {
    border-radius: 8px!important;
    overflow: visible!important;
}

.style_cardContentTable__6QY6A {
    padding: 0!important;
}

.style_cardHeaderTable__2uZzY {
    padding: 25px!important;
    font-weight: "bold";
}

.style_cardHeaderTitleTable__1qAdO {
    font-weight: bold!important;
    font-size: 1.4rem!important;
}


.style_memberWrapper__254x7 {
  margin-right: 5px;
  position: relative;
  background: var(--grey0);
  border-radius: 15px;
  margin-right: 10px;
  padding: 5px 10px 5px 10px;
}

.style_memberItem__2_uZb:hover .style_memberDelete__1uXE5 {
  display: -webkit-flex;
  display: flex;
}

.style_memberIcon__Whb7i {
  margin-right: 5px;
}

.style_memberDelete__1uXE5 {
  display: none;
  position: absolute;
  top: -5px;
  right: 3px;
  z-index: 10;
  background: #ffffff;
  color: var(--blue60);
  border-radius: 50%;
  width: 15px;
  height: 15px;
  box-shadow: 1px 2px 4px rgba(0,0,0,.3);
  font-size: 1rem;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.style_toggleAddMember__2MLr8 {
  color: var(--blue60);
  text-decoration: underline;
  cursor: pointer;
}

.style_nameWrapper__lTnYv {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column; 
}

.style_groupName__R5Nn2 {
  font-size: 12px;
}
.style_groupTypeName__b1QFQ {
  font-size: 10px;
  color: var(--grey60);
}
.style_groupIconWrapper__uYXzI {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  background: #F1F3F4;    
  margin-right: 10px;
}

.style_flex__2g--W {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.style_flexEnd__2g6bv {
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
}

.style_lastCell__ZIi4S {
  width: 50px;
}

.style_pointer__3RKxM:hover {
  cursor: pointer;
}

.style_relative__3zjDo {
  position: relative;
}

.style_hover__2eBEE:hover {
  cursor: pointer;
}
.style_mainButton__2LQnE {
  font-weight: normal;
  border-radius: 3px;
  padding: 22px;
  background: var(--blue70) !important;
  box-shadow: none !important;
}

.style_widgetItem__4Otif {
  margin-right: 15px;
}

.style_widgetItem__4Otif:last-child {
  margin-right: 0px;
}

.style_menu__2wbus {
  position: absolute;
  z-index: 10;
  right: 45px;
  top: 0;
}

.style_searchBoxInput__39nyf {
  padding: 13px 15px !important;
}
.style_searchBoxInput__39nyf::-webkit-input-placeholder {
  color: var(--blue60);
}
.style_searchBoxInput__39nyf:-ms-input-placeholder {
  color: var(--blue60);
}
.style_searchBoxInput__39nyf::placeholder {
  color: var(--blue60);
}

.style_searchBoxOutline__3SoUk {
  border-color: var(--blue10) !important;
}
.style_searchBoxIcon__1ZINW {
  color: var(--blue40);
  font-size: 2.3rem;
}

.style_cardTable__1ZW1e {
  border-radius: 8px !important;
  overflow: visible !important;
}

.style_cardContentTable__3ssOI {
  padding: 0 !important;
}

.style_cardHeaderTable__17HAP {
  padding: 25px !important;
  font-weight: "bold";
}

.style_cardHeaderTitleTable__1_Iob {
  font-weight: bold !important;
  font-size: 1.4rem !important;
}

.style_dashboardCards__1Anvg {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row nowrap;
          flex-flow: row nowrap;
  -webkit-justify-content: stretch;
          justify-content: stretch;
  margin: 16px 0;
}
.style_headerDocument__2Oq8R > .style_title__1fZFX {
  color: #fff;
  font-weight: bold;
  margin-bottom: 10px;
  font-size: 18px;
}
.style_cardInformation__19zrP {
  margin-left: 5px;
  margin-right: 5px;
}
.style_cardInformation__19zrP:first-of-type {
  margin-left: 0px;
}
.style_cardInformation__19zrP:last-of-type {
  margin-right: 0px;
}

.style_cardInformation__19zrP > .style_cardInformationContent__2lJrB {
  background-color: #133774;
  padding: 10px 15px;
  border-radius: 5px;
}
.style_cardInformation__19zrP > .style_cardInformationContent__2lJrB .style_logo__2t1kx {
  color: #fff;
  margin-right: 10px;
}
.style_cardInformation__19zrP > .style_cardInformationContent__2lJrB .style_title__1fZFX {
  color: #fff;
  margin: 0;
  font-size: 12px;
  font-weight: 500;
}

.style_taskContainer__1rkLr {
  margin-top: 30px;
  background: #fff;
  border-radius: 8px;
  box-shadow: var(--shadow4dp);
  min-height: 200px;
  padding: 5px;
  margin-bottom: 20px;
}

.style_taskContent__20nhh {
  width: 100%;
  padding: 15px 15px 0;
}
.style_taskContent__20nhh .style_tabsCustom__1M5Sl {
  padding-left: 5px;
  padding-right: 10px;
}
.style_taskContent__20nhh .style_tabsCustom__1M5Sl .style_tabsIndicator__2YdrT {
  height: 4px;
  background-color: #4f91ff;
  border-radius: 2px;
}

.style_taskContent__20nhh .style_tabsCustom__1M5Sl .style_customButton__uuriB {
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
  margin-left: 20px;
  margin-right: 20px;
}
.style_taskContent__20nhh .style_tabsCustom__1M5Sl .style_customButton__uuriB:first-of-type {
  margin-left: 0;
}
.style_taskContent__20nhh .style_tabsCustom__1M5Sl .style_customButton__uuriB:last-of-type {
  margin-right: 0;
}
.style_taskContent__20nhh .style_customPanel__1I6BW {
  padding: 20px 0 0;
}

.style_tableContainer__3qsmp .style_tableToolbar__2KuUX {
  margin-bottom: 20px;
}

.style_tableContainer__3qsmp .style_tableToolbar__2KuUX .style_containerButton__1s18H {
  margin-bottom: 10px;
}
.style_tableContainer__3qsmp .style_tableToolbar__2KuUX .style_mainButton__3mN5r {
  font-weight: 500;
  margin-left: 5px;
  margin-right: 5px;
  font-size: 14px;
}
.style_tableContainer__3qsmp .style_tableToolbar__2KuUX .style_searchInput__2IR-p {
  margin-right: 10px;
  font-size: 14px;
  width: 250px;
}
.style_tableContainer__3qsmp .style_tableToolbar__2KuUX .style_customSelect__2OPEN {
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
}
.style_customContainerTable__1NBNR {
  max-height: calc(100vh - 440px);
}
.style_customSearchInput__2DcHm {
  border-radius: 4px;
  border: 1px solid var(--grey30);
  height: 42px;
  width: 100%;
  padding-left: 32px;
  padding-right: 15px;
}

/* CHIPS STYLES*/

._type_def__3pS9J[class*="tag"] {
  min-width: 0px;
  height: 2rem;
  font-style: normal;
  font-weight: normal;
  line-height: 1.5rem;
  font-size: var(--font_size_body);
  letter-spacing: var(--letter_spacing_body);
  text-transform: none;
  padding: 0.25rem 0.625rem;
  -webkit-justify-content: left;
          justify-content: left;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
._type_def__3pS9J[class*="tag"]:focus,
._type_dense__20JLD[class*="tag"]:focus {
  outline: none !important;
}

._type_def__3pS9J[class*="tag"] span[icon]:first-of-type {
  margin-left: 0;
  margin-right: 0.375rem;
}
._type_def__3pS9J[class*="tag"] span[icon]:last-of-type {
  margin-right: 0;
  margin-left: 0.375rem;
}
._type_def__3pS9J[class*="tag"] span[icon]:first-of-type > svg {
  height: 1.25rem;
  width: 1.25rem;
}
._type_def__3pS9J[class*="tag"] span[class*="icon-tick"]:first-of-type {
  width: 0;
  height: 0;
  margin-right: 0;
  -webkit-transform: scale(0);
          transform: scale(0);
  transition: all 200ms ease-in;
}
._type_def__3pS9J._type_active__2nHMt[class*="tag"] span[class*="icon-tick"]:first-of-type {
  height: 1.25rem;
  width: 1.25rem;
  margin-right: 0.375rem;
  -webkit-transform: scaleX(1);
          transform: scaleX(1);
  transition: all 200ms ease-in;
}
._type_def__3pS9J[class*="tag"] button[class*="tag-remove"] > span[class*="small-cross"] {
  margin-right: 0;
  margin-left: 0.375rem;
}
._type_def__3pS9J[class*="tag"] button[class*="tag-remove"] {
  padding: 0;
  padding-right: 0;
  margin-right: 0 !important;
}
._type_def__3pS9J[class*="tag"] span[class*="text"] {
  margin-right: 0;
}
/*dense*/
._type_dense__20JLD[class*="tag"] {
  min-width: 0px;
  height: 1.25rem;
  min-height: 1.25rem;
  font-style: normal;
  font-weight: normal;
  line-height: 1rem;
  font-size: var(--font_size_caption);
  letter-spacing: var(--letter_spacing_caption);
  text-transform: none;
  padding: 0.125rem 0.375rem;
  -webkit-justify-content: left;
          justify-content: left;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
._type_dense__20JLD[class*="tag"] span[icon]:first-of-type {
  margin-left: 0;
  margin-right: 0.25rem;
}
._type_dense__20JLD[class*="tag"] span[icon]:last-of-type {
  margin-right: 0;
  margin-left: 0.25rem;
}
._type_dense__20JLD[class*="tag"] span[icon]:first-of-type > svg {
  height: 1rem;
  width: 1rem;
}
._type_dense__20JLD[class*="tag"] span[class*="icon-tick"]:first-of-type {
  width: 0;
  height: 0;
  margin-right: 0;
  -webkit-transform: scale(0);
          transform: scale(0);
  transition: all 200ms ease-in;
}
._type_dense__20JLD._type_active__2nHMt[class*="tag"] span[class*="icon-tick"]:first-of-type {
  height: 1rem;
  width: 1rem;
  margin-right: 0.25rem;
  -webkit-transform: scaleX(1);
          transform: scaleX(1);
  transition: all 200ms ease-in;
}
._type_dense__20JLD[class*="tag"] button[class*="tag-remove"] > span[class*="small-cross"] {
  margin-right: 0;
  margin-left: 0.125rem;
}
._type_dense__20JLD[class*="tag"] button[class*="tag-remove"] {
  padding: 0;
  padding-right: 0;
  margin-right: 0 !important;
}
._type_dense__20JLD[class*="tag"] span[class*="text"] {
  margin-right: 0;
}
/*END dense*/
/*ROUNDED*/
._type_round__1iS54[class*="tag"] {
  border-radius: 99px;
}
/*-----------------------*/

/*WITH NUMBER */
._type_def__3pS9J[class*="tag"]._type_number__2CIEj,
._type_dense__20JLD[class*="tag"]._type_number__2CIEj {
  line-height: 1.25rem;
}
._type_def__3pS9J[class*="tag"]._type_number__2CIEj span > div {
  min-width: 1.25rem;
  height: 1.25rem;
  display: inline-block;
  border-radius: 0.25rem;
  text-align: center;
  margin-right: 0.375rem;
  font-size: var(--font_size_body_small);
  letter-spacing: var(--letter_spacing_body_small);
  font-weight: var(--font_weight_body_medium);
  padding: 0 0.4375rem;
}
._type_def__3pS9J[class*="tag"]._type_number__2CIEj._type_round__1iS54 span > div {
  border-radius: 99px;
}

._type_dense__20JLD[class*="tag"]._type_number__2CIEj span > div {
  min-width: 0;
  height: 1.25rem;
  display: inline-block;
  text-align: center;
  margin-right: 0.25rem;
  font-size: var(--font_size_body_small);
  letter-spacing: var(--letter_spacing_body_small);
  font-weight: var(--font_weight_body_medium);
  padding: 0 0;
  line-height: 1.25rem;
}

/*-----------*/

/*WITH AVATAR*/
._type_def__3pS9J[class*="tag"]._type_avatar__33pqm,
._type_dense__20JLD[class*="tag"]._type_avatar__33pqm {
  border-radius: 99px;
}
._type_dense__20JLD[class*="tag"]._type_avatar__33pqm {
  padding-left: 0;
}
._type_def__3pS9J[class*="tag"]._type_avatar__33pqm span[class*="text"],
._type_dense__20JLD[class*="tag"]._type_avatar__33pqm span[class*="text"] {
  line-height: 0;
}
._type_def__3pS9J[class*="tag"]._type_avatar__33pqm span > div:first-of-type,
._type_dense__20JLD[class*="tag"]._type_avatar__33pqm span > div:first-of-type {
  width: 1.5rem;
  height: 1.5rem;
  display: inline-block;
  text-align: center;
  margin-right: 0.375rem;
  vertical-align: middle;
}
._type_dense__20JLD[class*="tag"]._type_avatar__33pqm span > div:first-of-type {
  width: 1.25rem;
  height: 1.25rem;
  margin-right: 0.25rem;
}
._type_def__3pS9J[class*="tag"]._type_avatar__33pqm span > div:first-of-type > img {
  width: 1.5rem;
  height: 1.5rem;
}
._type_dense__20JLD[class*="tag"]._type_avatar__33pqm span > div:first-of-type > img {
  width: 1.25rem;
  height: 1.25rem;
}
._type_dense__20JLD[class*="tag"]._type_avatar__33pqm._type_outlined__1ERs9 span > div:first-of-type > img {
  width: 1.125rem;
  height: 1.125rem;
  margin-top: 0.0625rem;
}
._type_def__3pS9J[class*="tag"]._type_avatar__33pqm span > div:last-of-type,
._type_dense__20JLD[class*="tag"]._type_avatar__33pqm span > div:last-of-type {
  display: inline-block;
  vertical-align: middle;
}
/**END AVATAR*/

/* CHIPS VIEW STYLES*/

/*SMOOTH STYLE*/
._view_smooth__271nm[class*="tag"],
._view_smooth__271nm[class*="tag"]:visited {
  color: var(--color70);
  background-color: var(--color5);
  transition: all 180ms ease-in;
}
._view_smooth__271nm[class*="tag"]:hover {
  box-shadow: var(--shadow2dp);
  background-color: var(--color5);
  transition: all 200ms ease-in;
}
._view_smooth__271nm[class*="tag"] svg {
  color: var(--color40);
}
._view_smooth__271nm[class*="tag"] button[class*="tag-remove"] {
  opacity: 1;
}
._view_smooth__271nm[class*="tag"]
  button[class*="tag-remove"]
  > span[class*="small-cross"]
  > svg {
  color: var(--color50);
}
._view_smooth__271nm[class*="tag"]
  button[class*="tag-remove"]:hover
  > span[class*="small-cross"]
  > svg {
  color: var(--color70);
  opacity: 1;
  transition: all 180ms ease-in;
}
._view_smooth__271nm[class*="tag"]._view_active__3sbTF {
  color: var(--color0);
  background-color: var(--color40);
  transition: all 180ms ease-in;
}
._view_smooth__271nm[class*="tag"]._view_active__3sbTF svg,
._view_smooth__271nm[class*="tag"]._view_active__3sbTF svg:hover {
  background-color: transparent;
  color: var(--color0);
}

._view_smooth__271nm[class*="tag"]._view_disabled__34Csq,
._view_smooth__271nm[class*="tag"]._view_disabled__34Csq svg,
._view_smooth__271nm[class*="tag"]._view_disabled__34Csq._view_active__3sbTF,
._view_smooth__271nm[class*="tag"]._view_disabled__34Csq._view_active__3sbTF svg,
._view_smooth__271nm[class*="tag"]._view_disabled__34Csq:hover,
._view_smooth__271nm[class*="tag"]._view_disabled__34Csq
  button[class*="tag-remove"]:hover
  > span[class*="small-cross"]
  > svg,
._view_smooth__271nm[class*="tag"]._view_disabled__34Csq
  button[class*="tag-remove"]
  > span[class*="small-cross"]
  > svg,
._view_smooth__271nm[class*="tag"]._view_disabled__34Csq
  button[class*="tag-remove"]:hover
  > span[class*="small-cross"]
  > svg,
._view_smooth__271nm[class*="tag"]._view_disabled__34Csq button[class*="tag-remove"],
._view_smooth__271nm[class*="tag"]._view_disabled__34Csq button[class*="tag-remove"]:hover {
  background-color: var(--color5);
  color: var(--color30);
  box-shadow: none;
  cursor: no-drop;
}

/* END STYLE */

/*OUTLINED STYLE*/
._view_outlined__2QkAk[class*="tag"],
._view_outlined__2QkAk[class*="tag"]:visited {
  color: var(--color70);
  background-color: var(--white);
  border: 0.0625rem solid var(--color30);
  transition: all 100ms ease-in;
}
._view_outlined__2QkAk[class*="tag"]:hover {
  box-shadow: 0 0 0 1px var(--color30);
  background-color: var(--white);
  border: 0.0625rem solid var(--color30);
  transition: all 200ms ease-in;
}
._view_outlined__2QkAk[class*="tag"]._view_active__3sbTF {
  background-color: var(--color5);
  border: 0.0625rem solid var(--color50);
  box-shadow: 0 0 0 1px var(--color50);
  color: var(--color70);
  transition: all 150ms ease-in;
}
._view_outlined__2QkAk[class*="tag"]._view_active__3sbTF svg,
._view_outlined__2QkAk[class*="tag"]._view_active__3sbTF svg:hover {
  color: var(--color70);
  transition: all 150ms ease-in;
}

._view_outlined__2QkAk[class*="tag"] button[class*="tag-remove"] {
  background: transparent;
  opacity: 1;
  transition: all 150ms ease-in;
}
._view_outlined__2QkAk[class*="tag"] svg {
  color: var(--color30);
  transition: all 150ms ease-in;
}
._view_outlined__2QkAk[class*="tag"] button[class*="tag-remove"] > span > svg {
  color: var(--color50);
}

._view_outlined__2QkAk[class*="tag"] button[class*="tag-remove"]:hover > span > svg {
  color: var(--color70);
  transition: all 180ms ease-in;
}

._view_outlined__2QkAk[class*="tag"]._view_disabled__34Csq,
._view_outlined__2QkAk[class*="tag"]._view_disabled__34Csq svg,
._view_outlined__2QkAk[class*="tag"]._view_disabled__34Csq._view_active__3sbTF,
._view_outlined__2QkAk[class*="tag"]._view_disabled__34Csq._view_active__3sbTF svg,
._view_outlined__2QkAk[class*="tag"]._view_disabled__34Csq:hover,
._view_outlined__2QkAk[class*="tag"]._view_disabled__34Csq svg:hover,
._view_outlined__2QkAk[class*="tag"]._view_disabled__34Csq button[class*="tag-remove"] > span > svg,
._view_outlined__2QkAk[class*="tag"]._view_disabled__34Csq
  button[class*="tag-remove"]:hover
  > span
  > svg {
  background-color: transparent;
  color: var(--color30);
  border: 0.0625rem solid var(--color10);
  box-shadow: none;
  cursor: no-drop;
  opacity: 1;
}
._view_outlined__2QkAk[class*="tag"]._view_disabled__34Csq button[class*="tag-remove"] > span > svg,
._view_outlined__2QkAk[class*="tag"]._view_disabled__34Csq button[class*="tag-remove"]:hover > span > svg,
._view_outlined__2QkAk[class*="tag"]._view_disabled__34Csq svg:hover,
._view_outlined__2QkAk[class*="tag"]._view_disabled__34Csq svg,
._view_outlined__2QkAk[class*="tag"]._view_disabled__34Csq._view_active__3sbTF svg {
  border: none;
}
/* END STYLE */
/*WITH NUMBER*/
._view_smooth__271nm[class*="tag"]._view_number__2axTF span > div,
._view_outlined__2QkAk[class*="tag"]._view_number__2axTF span > div {
  background: var(--color70);
  color: var(--color0);
}
._view_smooth__271nm[class*="tag"]._view_number__2axTF._view_dense__21Qa6 span > div,
._view_outlined__2QkAk[class*="tag"]._view_number__2axTF._view_dense__21Qa6 span > div {
  background: none;
  color: var(--color100);
}
._view_smooth__271nm[class*="tag"]._view_number__2axTF._view_disabled__34Csq span > div,
._view_outlined__2QkAk[class*="tag"]._view_number__2axTF._view_disabled__34Csq span > div {
  background: var(--color50);
}
._view_smooth__271nm[class*="tag"]._view_number__2axTF._view_disabled__34Csq._view_dense__21Qa6 span > div,
._view_outlined__2QkAk[class*="tag"]._view_number__2axTF._view_disabled__34Csq._view_dense__21Qa6 span > div {
  color: var(--color70);
  background: none;
}

.style_pageTools__XLmhB {
  font-weight: bold;
  font-size: 14px;
  color: var(--grey40);
}

.style_input__1tzQ6 {
  width: 30px;
  text-align: center;
  border-radius: 3px;
  border: 1px solid var(--grey10);
  padding: 3px 0;
}

.style_loadingBackdrop__124S8 {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  height: 750px;
  width: 100%;
}

.style_tableContainer__rwI8t {
  padding: 0px;
}

.style_table__2WGmE {
  max-height: 10vh;
  overflow-y: auto;
}

.style_row__IkSfZ {
  border-bottom: 2px solid transparent;
}

.style_info__3w7wH {
  font-family: var(--inter);
  padding: 10px;
  font-size: 12px;
  color: var(--grey50);
}


.style_page_wrapper__Km6r6 {
  width: 100%;
  height: 100%;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  border-radius: 2px;
  /*
  Introduced in Internet Explorer 10.
  See http://ie.microsoft.com/testdrive/HTML5/msUserSelect/
  */
  -ms-user-select: none;
  user-select: none;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  margin: 16px;
}

.style_page_wrapper__Km6r6:hover {
  background-color: rgba(0, 0, 0, 0.15);
}

.style_page_wrapper__Km6r6.style_active__no2sC {
  background-color: rgba(0, 0, 0, 0.15);
}

.style_page_wrapper__Km6r6.style_active__no2sC span.style_page_number__YF6j8 {
  font-weight: bold;
}
.style_page__2_PDL {
  background-color: #ffffff;
  margin-top: 16px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.5), 0 2px 8px rgba(0, 0, 0, 0.3);
}

.style_page__2_PDL > .style_react-pdf__Page_img__1pjrl {
  /* padding: 16px; */
  width: 100%;
  height: auto;
}

.style_active__no2sC {
}


.style_page_number_wrapper__EPuTm {
  width: 100%;
  text-align: center;
  padding: 12px 0px;
}

.style_page_number__YF6j8 {
  font-family: var(--inter);
  font-size: var(--font_size_body_small);
  color: var(--grey60);
}

.style_pdf_viewer__1MbgC {
  overflow: auto;
  height: 84vh;
  position: relative;
  background: var(--grey10);
}

.style_pdf_page__2wjJN {
  margin: 20px auto;
  border: 10px solid transparent;
  box-sizing: content-box;
}

.style_progress__zwx5H {
  margin: 20px auto;
  height: 20px;
  width: 80%;
}

.style_pageTools__1GcC9 {
  font-weight: bold;
  font-size: 14px;
  color: var(--grey40);
}

.style_input__1Dwea {
  width: 40px;
  text-align: center;
  border-radius: 3px;
  border: 1px solid var(--grey10);
  padding: 3px 0;
}

.style_loadingBackdrop__22zqY {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  height: 750px;
  width: 100%;
}

.style_selectbox__U25TC {
  z-index: 9000;
  position: absolute;
  cursor: default;
  background: none;
  border: 1px dashed grey;
}

.style_deselectButton__rNJ2Z {
  position: absolute;
  top: -10px;
  right: -10px;
  background: #fff;
  box-shadow: 0px 2px 4px rgba(27, 78, 163, 0.2);
}

.style_thumbWrapper__1SFk5 {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: column;
          flex-flow: column;
  height: 100%;
  width: 100%;
  /* padding: 10px; */
  overflow: auto;
}

.style_page_wrapper__2hggs {
  width: 100%;
  height: 100%;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  border-radius: 2px;
  /*
  Introduced in Internet Explorer 10.
  See http://ie.microsoft.com/testdrive/HTML5/msUserSelect/
  */
  -ms-user-select: none;
  user-select: none;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  margin: 16px;
}

.style_page_wrapper__2hggs:hover {
  background-color: rgba(0, 0, 0, 0.15);
}

.style_page_wrapper__2hggs.style_active__1a5f8 {
  background-color: rgba(0, 0, 0, 0.15);
}

.style_page_wrapper__2hggs.style_active__1a5f8 span.style_page_number__3NNle {
  font-weight: bold;
}
.style_page__29NDI {
  background-color: #ffffff;
  margin-top: 16px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.5), 0 2px 8px rgba(0, 0, 0, 0.3);
}

.style_page__29NDI > .style_react-pdf__Page_img__2yB64 {
  /* padding: 16px; */
  width: 100%;
  height: auto;
}

.style_variable_name__1RqTI {
  display: block;
  font-family: var(--inter);
  font-size: 14px;
}

.style_variable_type__2DtbI {
  display: block;
  font-family: var(--inter);
  font-size: 12px;
  color: var(--grey60);
}

.style_page_number_wrapper__7yENX {
  width: 100%;
  text-align: center;
  margin: 12px 0px;
}

.style_page_number__3NNle {
  font-family: var(--inter);
  font-size: var(--font_size_body_small);
  color: var(--grey60);
}

.style_pdf_viewer__sZ9-S {
  overflow: auto;
  height: 100%;
  position: relative;
  background: var(--grey10);
}

.style_pdf_page__ebHNU {
  margin: 20px auto;
  border: 10px solid transparent;
  box-sizing: content-box;
}

.style_progress__2mzTh {
  margin: 20px auto;
  height: 20px;
  width: 80%;
}

.style_input_wrapper__2_OQo {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin-bottom: 14px;
  background-color: #fff;
}

.style_input_wrapper__2_OQo:not(:last-child) {
  margin-bottom: 14px;
}

.style_header_button_wrapper__issDx {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.style_nested_wrapper__RRdq4 {
  border-bottom: 1px solid var(--grey10);
  margin-bottom: 5px;
  margin-left: 10px;
  background-color: #fff;
}

.style_nested_parent_wrapper__3PF7e {
  margin-bottom: 10px;
}

.style_nested_header_wrapper__2Mnek {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  cursor: pointer;
  margin: 5px 0px;
}

.style_nested_header__3GEdu {
  margin: 0;
  font-family: var(--inter);
  font-size: var(--font_size_caption);
  color: var(--grey60);
  padding: 6px 0px;
}

.style_count_badge__hYvuN {
  display: block;
  font-family: var(--inter);
  font-size: var(--font_size_body_small);
  color: var(--grey70);
  font-weight: 500;
  text-align: center;
  border-radius: 50%;
  background: var(--grey10);
  line-height: 20px;
  height: 20px;
  width: 20px;
  margin-right: 5px;
}

.style_border_bottom__1Ogmq {
  background-color: #fff;
}

.style_border_bottom__1Ogmq:not(:last-child) {
  border-bottom: 1px solid var(--grey10);
  margin-bottom: 10px;
}

.style_userBoxWrapperBounding__3W4kr {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row-reverse;
          flex-flow: row-reverse;
  position: absolute;
  top: -15px;
  right: -2px;
}

.style_userBox__nmyfC {
  display: block;
  height: 15px;
  padding: 0px 5px;
  font-family: var(--inter);
  font-size: 10px;
  color: #fff;
  white-space: nowrap;
}

.style_popover__-N4wi {
  margin-top: 20px;
  box-shadow: none;
}

.style_infoContainer__1Ua8l {
  border-radius: 0.25rem;
  margin-top: 10px;
  background: #4a4b57;
  color: #ffffff;
  padding: 1rem;
  width: 314px;
  box-shadow: 0px 1px 2px rgba(58, 58, 68, 0.24),
    0px 2px 4px rgba(90, 91, 106, 0.24);
}

.style_dotInfo__3g65t {
  display: block;
  margin-top: 2px;
  width: 12px;
  border-top: 6px solid var(--blue40);
  border-bottom: 6px solid var(--yellow40);
  border-radius: 50%;
}

.style_dotError__2LJmi {
  display: block;
  margin-top: 2px;
  width: 12px;
  height: 12px;
  background: var(--red40);
  border-radius: 50%;
}

.style_searchResultContainer__2WhjZ {
  margin-top: 16px;
  height: calc(100vh - 242px);
}

.style_emptySearchResult__pwG8n {
  width: 100%;
  height: 36px;
  background: var(--grey0);
  border-radius: 4px;
  font-size: 14px;
  font-family: var(--inter);
  color: var(--grey100);
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.style_resultList__R7_IV {
  width: 100%;
  height: 25px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  cursor: pointer;
  margin-bottom: 24px;
  padding-left: 5px;
  padding-right: 5px;
}

.style_resultList__R7_IV span {
  display: inline-block;
  font-size: 12px;
  font-family: var(--inter);
  color: var(--grey100);
  -webkit-flex-grow: 0;
          flex-grow: 0;
  white-space: nowrap;
}

.style_matchText__1Iw01 {
  width: 90%;
  overflow: hidden;
}

.style_review_buttons_container__34Q20 {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.style_item_options__3pOHz {
  max-height: 300px;
  width: 221px;
  overflow-y: auto;
}

.style_item_options__3pOHz ul {
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: 14px;
}

.style_item_options__3pOHz ul li {
  width: 100%;
  display: block;
  padding: 10px 15px;
  cursor: pointer;
  transition: all 500ms ease;
  color: var(--grey60);
}

.style_item_options__3pOHz ul li:hover {
  background-color: var(--grey0);
}

.style_unsaved__1Ubqk {
  font-size: 12px;
  font-weight: 600;
  color: var(--orange50);
}

.style_accordion_title_container__K72g5 {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
}

.style_pageTools__14ymY {
  font-weight: bold;
  font-size: 14px;
  color: var(--grey40);
}

.style_input__3AZkn {
  width: 30px;
  text-align: center;
  border-radius: 3px;
  border: 1px solid var(--grey10);
  padding: 3px 0;
}

.style_loadingBackdrop__3_2Yz {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  height: 750px;
  width: 100%;
}

.style_deselectButton__3d9Ch {
  position: absolute;
  top: -10px;
  right: -10px;
  background: #fff;
  box-shadow: 0px 2px 4px rgba(27, 78, 163, 0.2);
}

.style_inputNotes__1SAdE {
  border: 1px solid var(--grey30);
  padding: 10px;
  resize: none;
  font-family: var(--inter);
  font-size: var(--font_size_body_small);
  height: 100%;
  width: 100%;
}

.style_userBoxWrapperBounding__2oLS9 {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row-reverse;
          flex-flow: row-reverse;
  position: absolute;
  top: -15px;
  right: -2px;
}

.style_userBoxWrapperPage__LGe-P {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row-reverse;
          flex-flow: row-reverse;
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 100;
}

.style_userBox__rGw8n {
  display: block;
  height: 15px;
  padding: 0px 5px;
  font-family: var(--inter);
  font-size: 10px;
  color: #fff;
  white-space: nowrap;
}

.style_thumbWrapper__234r4 {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: column;
          flex-flow: column;
  height: 100%;
  width: 100%;
  overflow: auto;
}

.style_page_wrapper__xcVGt {
  width: 100%;
  height: 100%;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  border-radius: 2px;
  /*
  Introduced in Internet Explorer 10.
  See http://ie.microsoft.com/testdrive/HTML5/msUserSelect/
  */
  -ms-user-select: none;
  user-select: none;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  margin: 16px;
}

.style_page_wrapper__xcVGt:hover {
  background-color: rgba(0, 0, 0, 0.15);
}

.style_page_wrapper__xcVGt.style_active__3EgEt {
  background-color: rgba(0, 0, 0, 0.15);
}

.style_page_wrapper__xcVGt.style_active__3EgEt span.style_page_number__14-zo {
  font-weight: bold;
}
.style_page__3w09Y {
  background-color: #ffffff;
  margin-top: 16px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.5), 0 2px 8px rgba(0, 0, 0, 0.3);
}

.style_page__3w09Y > .style_react-pdf__Page_img__3l-mK {
  /* padding: 16px; */
  width: 100%;
  height: auto;
}

.style_page_number_wrapper__Xiewa {
  width: 100%;
  text-align: center;
  margin: 12px 0px;
}

.style_page_number__14-zo {
  font-family: var(--inter);
  font-size: var(--font_size_body_small);
  color: var(--grey60);
}

.style_page_number_wrapper__Xiewa {
  width: 100%;
  text-align: center;
  margin: 12px 0px;
}

.style_page_number__14-zo {
  font-family: var(--inter);
  font-size: var(--font_size_body_small);
  color: var(--grey60);
}

.style_pdf_viewer__17Su3 {
  overflow: auto;
  height: 100%;
  position: relative;
  background: var(--grey10);
}

.style_pdf_page__3n6B3 {
  margin: 20px auto;
  border: 10px solid transparent;
  box-sizing: content-box;
}

.style_progress__1Ym2C {
  margin: 20px auto;
  height: 20px;
  width: 80%;
}

.style_variable_name__2WxJv {
  display: block;
  font-family: var(--inter);
  font-size: var(--font_size_body_small);
}

.style_variable_type__2LW4c {
  display: block;
  font-family: var(--inter);
  font-size: var(--font_size_caption);
  color: var(--grey60);
}

.style_variable_text_input__wUoSI {
  box-sizing: border-box;
  height: 28px;
  width: 100%;
  border: 1px solid var(--grey20);
  padding: 2px 8px;
}

.style_input_wrapper__PMs6B {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin-bottom: 14px;
}

.style_input_wrapper__PMs6B:not(:last-child) {
  margin-bottom: 14px;
}

.style_input_icon__1dJa9 {
  color: var(--grey50);
  margin-right: 7px; 
}

.style_select_input__1tWwp {
  box-sizing: border-box;
  border: 1px solid var(--grey20);
  background: #fff;
  width: 100%;
  padding: 2px 4px;
  color: var(--grey60);
  font-family: var(--inter);
  font-size: var(--font_size_caption);
  height: 28px;
}

.style_header_button_wrapper__23zp5 {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.style_nested_parent_wrapper__2nl1J {
  margin-bottom: 10px;
}

.style_nested_header_wrapper__11o1W {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  cursor: pointer;
  margin: 5px 0px;
}

.style_nested_header__3o0xG {
  margin: 0;
  font-family: var(--inter);
  font-size: var(--font_size_caption);
  color: var(--grey60);
  padding: 6px 0px;
}

.style_count_badge__1M_Gr {
  display: block;
  font-family: var(--inter);
  font-size: var(--font_size_body_small);
  color: var(--grey70);
  font-weight: 500;
  text-align: center;
  border-radius: 50%;
  background: var(--grey10);
  line-height: 20px;
  height: 20px;
  width: 20px;
  margin-right: 5px;
}

.style_border_bottom__3xwa6:not(:last-child) {
  border-bottom: 1px solid var(--grey10);
  margin-bottom: 10px;
}

.style_input_focused__1XlFt {
  border: 2px solid var(--teal40);
}

.style_item_options__2LBo0 {
  max-height: 300px;
  width: 221px;
  overflow-y: auto;
}

.style_item_options__2LBo0 ul {
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: 14px;
}

.style_item_options__2LBo0 ul li {
  width: 100%;
  display: block;
  padding: 10px 15px;
  cursor: pointer;
  transition: all 500ms ease;
  color: var(--grey60);
}

.style_item_options__2LBo0 ul li:hover {
  background-color: var(--grey0);
}

.style_searchResultContainer__2J5kM {
  margin-top: 16px;
  height: calc(100vh - 242px);
}

.style_emptySearchResult__2vt-o {
  width: 100%;
  height: 36px;
  background: var(--grey0);
  border-radius: 4px;
  font-size: 14px;
  font-family: var(--inter);
  color: var(--grey100);
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.style_resultList__3AZuo {
  width: 100%;
  height: 25px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  cursor: pointer;
  margin-bottom: 24px;
  padding-left: 5px;
  padding-right: 5px;
}

.style_resultList__3AZuo span {
  display: inline-block;
  font-size: 12px;
  font-family: var(--inter);
  color: var(--grey100);
  -webkit-flex-grow: 0;
          flex-grow: 0;
  white-space: nowrap;
}

.style_matchText__vAitW {
  width: 90%;
  overflow: hidden;
}

.style_accordion_title_container__2VN1x {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
}
.react-datepicker-wrapper {
  display: inline-block;
  padding: 0;
  margin: 0;
  width: 100%;
}

.style_searchWrapper__1t2k1 {
  background: #fff;
  padding: 15px 10px;
  margin-bottom: 20px;
}

.style_label__1stN5 {
  font-family: var(--inter);
  font-weight: bold;
  margin-bottom: 8px;
}

.style_formLabel__3fVJE {
  font-family: var(--inter);
  font-size: var(--font_size_body_small);
  color: var(--grey50);
  margin-bottom: 3px;
}

.style_roundBox__3YPeQ {
  border-radius: .8em;
}

.style_mainButton__3LIhC {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
}

.style_menu__2wT_c {
  position: absolute;
  z-index: 10;
  right: 45px;
  top: 0;
}

.style_select__2HXll {
  margin-bottom: 20px;
}

.style_flexCell__2oG7n {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-end;
          align-items: flex-end;
} 

.style_tableContainer__3KrBG {
  background: #fff;
  border-radius: 8px;
  box-shadow: var(--shadow4dp);
}

.style_tableToolbar__2L00I {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding: 15px 22px;
}

.style_tableTitle__3Dgh2 {
  font-family: var(--inter);
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 158%;
  letter-spacing: 0.004em;
  margin: 0;
}

.style_searchInput__2pFf4 {
  width: 191px;
}

.style_noVariable__1vVOv {
  display: block;
  margin-top: 10px;
  margin-left: 10px;
}

/**CARD*/

._type_def__f6LZv {
  border-radius: 0.5rem;
  padding: 0;
}

/*CARD*/
/*filled*/
._view_filled__2ElC4 {
  background-color: var(--color50);
  border: none;
  box-shadow: var(--shadow2dp);
  transition: all 0.2s cubic-bezier(0.4, 1, 0.75, 0.9);
}
._view_filled__2ElC4[class*="interactive"]:hover {
  box-shadow: var(--shadow8dp);
  background-color: var(--color70);
  transition: all 0.2s cubic-bezier(0.4, 1, 0.75, 0.9);
}
._view_filled__2ElC4[class*="interactive"]:active {
  box-shadow: var(--shadow2dp);
  background-color: var(--color50);
  opacity: 1;
  transition: all 0.2s cubic-bezier(0.4, 1, 0.75, 0.9);
}

/*smooth*/
._view_smooth__1nYmQ {
  background-color: var(--color0);
  border: none;
  box-shadow: none;
  transition: all 0.2s cubic-bezier(0.4, 1, 0.75, 0.9);
}
._view_smooth__1nYmQ[class*="interactive"]:hover {
  background-color: var(--color10);
  box-shadow: none;
  transition: all 0.2s cubic-bezier(0.4, 1, 0.75, 0.9);
}
._view_smooth__1nYmQ[class*="interactive"]:active {
  box-shadow: none;
  background-color: var(--color0);
  opacity: 1;
  transition: all 0.2s cubic-bezier(0.4, 1, 0.75, 0.9);
}

/*outlined*/
._view_outlined__VwxNv {
  background-color: var(--white);
  border: 1px solid var(--color20);
  box-shadow: none;
  transition: all 0.2s cubic-bezier(0.4, 1, 0.75, 0.9);
}
._view_outlined__VwxNv[class*="interactive"]:hover {
  border: 1px solid var(--color30);
  box-shadow: 0 0 0 1px var(--color30);
  transition: all 0.2s cubic-bezier(0.4, 1, 0.75, 0.9);
}
._view_outlined__VwxNv[class*="interactive"]:active {
  box-shadow: none;
  opacity: 1;
  transition: all 0.2s cubic-bezier(0.4, 1, 0.75, 0.9);
}
/*raised*/
._view_raised__M7D7f {
  background-color: var(--white);
  box-shadow: var(--shadow2dp);
  border: none;
  transition: all 0.2s cubic-bezier(0.4, 1, 0.75, 0.9);
}
._view_raised__M7D7f[class*="interactive"]:hover {
  box-shadow: var(--shadow8dp);
  transition: all 0.2s cubic-bezier(0.4, 1, 0.75, 0.9);
}
._view_raised__M7D7f[class*="interactive"]:active {
  box-shadow: var(--shadow2dp);
  opacity: 1;
  transition: all 0.2s cubic-bezier(0.4, 1, 0.75, 0.9);
}

.style_root__HnmrW {
  width: 100%;
}

.style_container__3mprn {
  box-shadow: var(--shadow4dp);
  border-radius: 8px;
  overflow: hidden;
}

.style_mainButton__1mUVd {
  letter-spacing: 1px;
  font-weight: normal;
  text-transform: uppercase;
  border-radius: 12px;
  padding-left: 30px;
  padding-right: 30px;
}

.style_heading__36DMw {
  margin: 0;
  color: var(--grey50);
  text-transform: uppercase;
}

.style_header__2zKBm {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  padding: 9.5px 12px;
  border-radius: 0;
  background: var(--grey0);
  height: 55px;
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
}

.style_flexCard__1u-iX {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: column;
          flex-flow: column;
  -webkit-align-items: stretch;
          align-items: stretch;
  padding: 0.5em;
  background: #fff;
  border-radius: 0;
  -webkit-flex-grow: 2;
          flex-grow: 2;
  overflow: auto;
}

.style_tableCard__yjEGA {
  padding: 0.5em;
  background: #fff;
  border-radius: 0;
  -webkit-flex-grow: 2;
          flex-grow: 2;
  overflow: auto;
}

.style_templateList__1wZw0 {
  position: relative;
  border-radius: 0.2em;
  margin-bottom: 5px;
  cursor: pointer;
  transition: 100ms background ease-in;
}

.style_templateName__3Yl21 {
  margin: 0;
  font-size: var(--font_size_caption);
}

.style_menu__tzzpC {
  position: absolute;
  z-index: 10;
  right: 5px;
  top: 0;
}

.style_formLabel__1pIiE {
  font-family: var(--inter);
  font-size: var(--font_size_body_small);
  color: var(--grey50);
  margin-bottom: 3px;
}

.style_select__t3ZpR {
  margin-bottom: 20px;
}

._type_default__31HJs {
  width: 100%;
  margin: 0.5rem 0;
  border-radius: 0.25rem;
}

._type_default__31HJs._type_vertical__R_ahw {
  margin: 0 0.5rem;
}

._view_color0__1NYMG {
  background: var(--color0);
}
._view_color5__1Jojh {
  background: var(--color5);
}
._view_color10__1g2c2 {
  background: var(--color10);
}
._view_color20__3_nmP {
  background: var(--color20);
}
._view_color30__2GHQh {
  background: var(--color30);
}
._view_color40__19eWg {
  background: var(--color40);
}
._view_color50__3avaN {
  background: var(--color50);
}
._view_color60__rSzyh {
  background: var(--color60);
}
._view_color70__3Tdw1 {
  background: var(--color70);
}
._view_color80__1rUb1 {
  background: var(--color80);
}
._view_color90__ojyWO {
  background: var(--color90);
}
._view_color100__2QB7v {
  background: var(--color100);
}

.style_searchWrapper__1SLCk {
  background: #fff;
  padding: 15px 10px;
  margin-bottom: 20px;
}

.style_label__csJPG {
  font-family: var(--inter);
  font-weight: bold;
  margin-bottom: 8px;
}

.style_formLabel__1Es1E {
  font-family: var(--inter);
  font-size: var(--font_size_body_small);
  color: var(--grey50);
  margin-bottom: 3px;
}

.style_roundBox__1LhxN {
  border-radius: .8em;
}

.style_mainButton__2UU0U {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
}

.style_menu__2jjdJ {
  position: absolute;
  z-index: 10;
  right: 45px;
  top: 0;
}

.style_select__N2anr {
  margin-bottom: 20px;
}

.style_flexCell__CMhDQ {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-end;
          align-items: flex-end;
} 

.style_tableContainer__vq6WV {
  background: #fff;
  border-radius: 8px;
  box-shadow: var(--shadow4dp);
}

.style_tableToolbar__x4erR {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding: 15px 22px;
}

.style_tableTitle__1CihM {
  font-family: var(--inter);
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 158%;
  letter-spacing: 0.004em;
  margin: 0;
}

.style_searchInput__227F5 {
  width: 191px;
}

.Style_sectionHeader__zWabF {
  color: #fff;
  font-size: 18px;
  font-weight: 700;
}

.Style_profilePanel__1GuO3 {
  height: 112px;
  width: 100%;
  background: #fff;
  border-radius: 8px;
  padding: 24px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
}

.Style_profileImage__1Ttlf {
  margin-right: 40px;
}

.Style_profileInfoWrapper__3B_81 {
  margin-right: 40px;
}

.Style_profileSubInfo__1ZELl {
  display: block;
  color: var(--grey50);
  font-size: 14px;
}

.Style_profileMainInfo__1AgUf {
  display: block;
  color: var(--grey100);
  font-size: 16px;
  font-weight: 500;
}

.Style_btnAction__3xGrj {
  border: 1px solid var(--blue70);
  border-radius: 4px;
  padding: 16px 20px;
  background-color: #fff;
  color: var(--blue70);
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
}

.Style_btnAction__3xGrj span {
  margin-right: 5px;
}

.Style_menu__1Et_7 {
  position: absolute;
  z-index: 10;
  right: 0;
  top: 51px;
}

.Style_dialogWrapper__1XwGp {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  -webkit-align-items: center;
          align-items: center;
  margin: 10px 0 0 0;
}

.Style_formLabel__1LQic {
  font-family: var(--inter);
  font-size: var(--font_size_body_small);
  color: var(--grey50);
  margin-bottom: 3px;
}

.Style_select__Dvu-u {
  margin-bottom: 20px;
}

.Style_searchInput__3B35t {
  border-radius: 4px;
  border: 1px solid var(--grey30);
  height: 37px;
  width: 100%;
  padding-left: 32px;
  padding-right: 15px;
}

/* BUTTONS TYPES*/

/*--DEFAULT--*/
._type_default__2X_OJ {
  text-transform: capitalize;
}

._type_default__2X_OJ > li::after {
  margin: 0 0.625rem;
  width: 1.25rem;
  height: 1.25rem;
}

._type_default__2X_OJ span[class*="icon"] {
  margin-right: 0.5rem;
}

._type_default__2X_OJ span[class*="breadcrumbs-collapsed"]::before {
  width: 1.25rem;
  height: 1.25rem;
  margin-left: auto;
  margin-right: auto;
}
._type_default__2X_OJ span[class*="breadcrumbs-collapsed"] {
  margin-right: 0;
  width: 2rem;
  height: 1.25rem;
  border-radius: 0.25rem;
  padding: 0;
}
._type_default__2X_OJ a[class*="breadcrumb"] {
  padding: 0.125rem 0.5rem;
  border-radius: 0.25rem;
}

/*MORE ICON COLOR*/
._view_primary__2sD1O span[class*="breadcrumbs-collapsed"]::before {
  background: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><g fill='%232979ff'><circle cx='2' cy='8.03' r='2'/><circle cx='14' cy='8.03' r='2'/><circle cx='8' cy='8.03' r='2'/></g></svg>")
    center no-repeat;
}
._view_success__Amp_7 span[class*="breadcrumbs-collapsed"]::before {
  background: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><g fill='%2343a047'><circle cx='2' cy='8.03' r='2'/><circle cx='14' cy='8.03' r='2'/><circle cx='8' cy='8.03' r='2'/></g></svg>")
    center no-repeat;
}
._view_warning__3Ysnm span[class*="breadcrumbs-collapsed"]::before {
  background: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><g fill='%23d6981b'><circle cx='2' cy='8.03' r='2'/><circle cx='14' cy='8.03' r='2'/><circle cx='8' cy='8.03' r='2'/></g></svg>")
    center no-repeat;
}
._view_danger__1enRd span[class*="breadcrumbs-collapsed"]::before {
  background: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><g fill='%23F44336'><circle cx='2' cy='8.03' r='2'/><circle cx='14' cy='8.03' r='2'/><circle cx='8' cy='8.03' r='2'/></g></svg>")
    center no-repeat;
}
._view_default__B2O3w span[class*="breadcrumbs-collapsed"]::before {
  background: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><g fill='%235A5B6A'><circle cx='2' cy='8.03' r='2'/><circle cx='14' cy='8.03' r='2'/><circle cx='8' cy='8.03' r='2'/></g></svg>")
    center no-repeat;
}
._view_primary_alt__3-HLk span[class*="breadcrumbs-collapsed"]::before {
  background: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><g fill='%235c6bc0'><circle cx='2' cy='8.03' r='2'/><circle cx='14' cy='8.03' r='2'/><circle cx='8' cy='8.03' r='2'/></g></svg>")
    center no-repeat;
}
._view_success_alt__2lh_V span[class*="breadcrumbs-collapsed"]::before {
  background: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><g fill='%23069697'><circle cx='2' cy='8.03' r='2'/><circle cx='14' cy='8.03' r='2'/><circle cx='8' cy='8.03' r='2'/></g></svg>")
    center no-repeat;
}
._view_warning_alt__3gkFH span[class*="breadcrumbs-collapsed"]::before {
  background: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><g fill='%23fb8200'><circle cx='2' cy='8.03' r='2'/><circle cx='14' cy='8.03' r='2'/><circle cx='8' cy='8.03' r='2'/></g></svg>")
    center no-repeat;
}
._view_danger_alt__1r6Wq span[class*="breadcrumbs-collapsed"]::before {
  background: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><g fill='%23ec407a'><circle cx='2' cy='8.03' r='2'/><circle cx='14' cy='8.03' r='2'/><circle cx='8' cy='8.03' r='2'/></g></svg>")
    center no-repeat;
}
/*CHEVRON ICON COLOR*/
._view_primary__2sD1O > li::after {
  background: url("data:image/svg+xml,<svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M13.71 9.29L7.71 3.29C7.53 3.11 7.28 3 7 3C6.45 3 6 3.45 6 4C6 4.28 6.11 4.53 6.29 4.71L11.59 10L6.3 15.29C6.11 15.47 6 15.72 6 16C6 16.55 6.45 17 7 17C7.28 17 7.53 16.89 7.71 16.71L13.71 10.71C13.89 10.53 14 10.28 14 10C14 9.72 13.89 9.47 13.71 9.29Z' fill='%232979FF'/></svg>");
}
._view_success__Amp_7 > li::after {
  background: url("data:image/svg+xml,<svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M13.71 9.29L7.71 3.29C7.53 3.11 7.28 3 7 3C6.45 3 6 3.45 6 4C6 4.28 6.11 4.53 6.29 4.71L11.59 10L6.3 15.29C6.11 15.47 6 15.72 6 16C6 16.55 6.45 17 7 17C7.28 17 7.53 16.89 7.71 16.71L13.71 10.71C13.89 10.53 14 10.28 14 10C14 9.72 13.89 9.47 13.71 9.29Z' fill='%2343a047'/></svg>");
}
._view_warning__3Ysnm > li::after {
  background: url("data:image/svg+xml,<svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M13.71 9.29L7.71 3.29C7.53 3.11 7.28 3 7 3C6.45 3 6 3.45 6 4C6 4.28 6.11 4.53 6.29 4.71L11.59 10L6.3 15.29C6.11 15.47 6 15.72 6 16C6 16.55 6.45 17 7 17C7.28 17 7.53 16.89 7.71 16.71L13.71 10.71C13.89 10.53 14 10.28 14 10C14 9.72 13.89 9.47 13.71 9.29Z' fill='%23d6981b'/></svg>");
}
._view_danger__1enRd > li::after {
  background: url("data:image/svg+xml,<svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M13.71 9.29L7.71 3.29C7.53 3.11 7.28 3 7 3C6.45 3 6 3.45 6 4C6 4.28 6.11 4.53 6.29 4.71L11.59 10L6.3 15.29C6.11 15.47 6 15.72 6 16C6 16.55 6.45 17 7 17C7.28 17 7.53 16.89 7.71 16.71L13.71 10.71C13.89 10.53 14 10.28 14 10C14 9.72 13.89 9.47 13.71 9.29Z' fill='%23F44336'/></svg>");
}
._view_default__B2O3w > li::after {
  background: url("data:image/svg+xml,<svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M13.71 9.29L7.71 3.29C7.53 3.11 7.28 3 7 3C6.45 3 6 3.45 6 4C6 4.28 6.11 4.53 6.29 4.71L11.59 10L6.3 15.29C6.11 15.47 6 15.72 6 16C6 16.55 6.45 17 7 17C7.28 17 7.53 16.89 7.71 16.71L13.71 10.71C13.89 10.53 14 10.28 14 10C14 9.72 13.89 9.47 13.71 9.29Z' fill='%235a5b6a'/></svg>");
}
._view_primary_alt__3-HLk > li::after {
  background: url("data:image/svg+xml,<svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M13.71 9.29L7.71 3.29C7.53 3.11 7.28 3 7 3C6.45 3 6 3.45 6 4C6 4.28 6.11 4.53 6.29 4.71L11.59 10L6.3 15.29C6.11 15.47 6 15.72 6 16C6 16.55 6.45 17 7 17C7.28 17 7.53 16.89 7.71 16.71L13.71 10.71C13.89 10.53 14 10.28 14 10C14 9.72 13.89 9.47 13.71 9.29Z' fill='%235c6bc0'/></svg>");
}
._view_success_alt__2lh_V > li::after {
  background: url("data:image/svg+xml,<svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M13.71 9.29L7.71 3.29C7.53 3.11 7.28 3 7 3C6.45 3 6 3.45 6 4C6 4.28 6.11 4.53 6.29 4.71L11.59 10L6.3 15.29C6.11 15.47 6 15.72 6 16C6 16.55 6.45 17 7 17C7.28 17 7.53 16.89 7.71 16.71L13.71 10.71C13.89 10.53 14 10.28 14 10C14 9.72 13.89 9.47 13.71 9.29Z' fill='%23069697'/></svg>");
}
._view_warning_alt__3gkFH > li::after {
  background: url("data:image/svg+xml,<svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M13.71 9.29L7.71 3.29C7.53 3.11 7.28 3 7 3C6.45 3 6 3.45 6 4C6 4.28 6.11 4.53 6.29 4.71L11.59 10L6.3 15.29C6.11 15.47 6 15.72 6 16C6 16.55 6.45 17 7 17C7.28 17 7.53 16.89 7.71 16.71L13.71 10.71C13.89 10.53 14 10.28 14 10C14 9.72 13.89 9.47 13.71 9.29Z' fill='%23fb8200'/></svg>");
}
._view_danger_alt__1r6Wq > li::after {
  background: url("data:image/svg+xml,<svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M13.71 9.29L7.71 3.29C7.53 3.11 7.28 3 7 3C6.45 3 6 3.45 6 4C6 4.28 6.11 4.53 6.29 4.71L11.59 10L6.3 15.29C6.11 15.47 6 15.72 6 16C6 16.55 6.45 17 7 17C7.28 17 7.53 16.89 7.71 16.71L13.71 10.71C13.89 10.53 14 10.28 14 10C14 9.72 13.89 9.47 13.71 9.29Z' fill='%23ec407a'/></svg>");
}
/*--------*/

/*SMOOTH STYLE*/
._view_smooth__2RT12 {
  color: var(--color100);
}
._view_smooth__2RT12 span[class*="breadcrumbs-collapsed"] {
  background: var(--color30);
  color: var(--color100);
}
._view_smooth__2RT12 a[class*="breadcrumb"] {
  color: var(--color80);
}
._view_smooth__2RT12 a[class*="breadcrumb"] > span[class*="icon"] {
  color: var(--color30);
}
._view_smooth__2RT12 a[class*="breadcrumb"]:hover {
  background: var(--color5);
  transition: all 0.2s cubic-bezier(0.4, 1, 0.75, 0.9);
}
._view_smooth__2RT12 a[class*="breadcrumb"]:hover > span[class*="icon"] {
  color: var(--color50);
  transition: all 0.2s cubic-bezier(0.4, 1, 0.75, 0.9);
}

/* END STYLE */
._view_popover__JWHHD div[class*="popover-content"] a[class*="menu-item"]:hover {
  background-color: var(--color5);
}
._view_popover__JWHHD div[class*="popover-arrow"] {
  display: none;
}

.Sidebar_sidebarContainer__34zKa a {
  text-decoration: none;
  color: var(--grey60);
}

.Sidebar_sidebarHeaderContainer__o1rXY {
  padding: 2px 4px;
  margin-bottom: 16px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
}

.Sidebar_sidebarLogo__3tb97 {
  margin-right: 4px;
  color: var(--blue100);
}

.Sidebar_sidebarLogo__3tb97 img {
  width: 28px;
  height: auto;
}

.Sidebar_sidebarLogoTitle__3X6yr {
  color: var(--grey70);
  font-size: 20px;
  font-weight: 600;
}

.Sidebar_sidebarItems__8iCBY {
  margin-top: 48px;
}

.Sidebar_sidebarItem__WUKLw {
  /* margin: 20px 0px; */
  margin-top: 8px;
  margin-bottom: 8px;
  padding: 14px 10px;
  border-radius: 8px;
}
.Sidebar_sidebarItem__WUKLw span {
  font-size: 14px;
  font-weight: 500;
  color: var(--grey60);
}

.Sidebar_sidebarItem__WUKLw.Sidebar_active__37KeR {
  background: var(--blue0);
}

.Sidebar_sidebarItemIcon__R_TcT {
  margin-right: 16px;
}

.Sidebar_sidebarItemSubitem__1jIfy {
  margin-left: 34px;
}
