.sectionHeader {
  color: #fff;
  font-size: 18px;
  font-weight: 700;
}

.profilePanel {
  height: 112px;
  width: 100%;
  background: #fff;
  border-radius: 8px;
  padding: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.profileImage {
  margin-right: 40px;
}

.profileInfoWrapper {
  margin-right: 40px;
}

.profileSubInfo {
  display: block;
  color: var(--grey50);
  font-size: 14px;
}

.profileMainInfo {
  display: block;
  color: var(--grey100);
  font-size: 16px;
  font-weight: 500;
}

.btnAction {
  border: 1px solid var(--blue70);
  border-radius: 4px;
  padding: 16px 20px;
  background-color: #fff;
  color: var(--blue70);
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
}

.btnAction span {
  margin-right: 5px;
}

.menu {
  position: absolute;
  z-index: 10;
  right: 0;
  top: 51px;
}

.dialogWrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 10px 0 0 0;
}

.formLabel {
  font-family: var(--inter);
  font-size: var(--font_size_body_small);
  color: var(--grey50);
  margin-bottom: 3px;
}

.select {
  margin-bottom: 20px;
}

.searchInput {
  border-radius: 4px;
  border: 1px solid var(--grey30);
  height: 37px;
  width: 100%;
  padding-left: 32px;
  padding-right: 15px;
}
